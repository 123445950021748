import React from "react";
import { Grid, Tooltip, Avatar } from "@mui/material";
import { useGetByReference } from "../../hooks/useGetByReference";
import { useReferenceData } from "../../hooks/useReferenceData";

const Personal = ({ responsables = {}, name }) => {
  const { data } = useGetByReference(responsables);
  const {referenceData} = useReferenceData(responsables);

  const combinedData = data.length? data : referenceData; 

  if ( combinedData === undefined || !combinedData.length) {
    return (
      <>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Tooltip arrow title={"Sin asignar"}>
            <Avatar
              sx={{ width: 45, height: 45, marginRight: 1 }}
              src={
                "https://www.movicaremx.com/IMG/app_mobile/FormatPNG/Perfil/Ajolomovi_H.png"
              }
            />
          </Tooltip>
        </Grid>
      </>
    );
  }
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        &nbsp;
        {combinedData.map((doc) => (
          <>
            <Tooltip title={doc?.DatosPersonales?.nombre || "Sin asignar"}>
              <Avatar
                alt={doc.nombre}
                src={doc?.imagen}
                style={{ marginLeft: 5 }}
              />
            </Tooltip>
            {name ? " " + doc?.DatosPersonales?.nombre || "Sin asignar" : null}
          </>
        ))}
      </Grid>
    </>
  );
};
export default Personal;
