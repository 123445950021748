/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  FormGroup,
  Modal,
  Box,
  Stack,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/firebasev2";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { useSnackbar } from "notistack";
import CustomClientData from "../../Components/Ordenes/V2/Details/CustomClientData";
import CanceledOrder from "../../Components/Ordenes/V2/Details/CanceledOrder";
import TicketsPersonal from "../../Components/Ordenes/V2/Details/TicketsPersonal";
import TableHistory from "../../Components/Ordenes/V2/Details/TableHistory";
import Dates from "../../Components/Ordenes/Dates";
import StatusXStaff from "../../Components/Ordenes/StatusXStaff";
import Cancelacion from "../../Components/Ordenes/Cancelacion";
import CustomTable from "../../Components/Ordenes/V2/CustomTable";
import { useDimensions } from "../../hooks/useDimensions";
import { cancelar_Pedido } from "../../Controllers/Ordenes/cancelar_Pedido";
import { RedButton } from "../../CSS/Outlined/RedButton";
import { DarkGreenButton } from "../../CSS/Contained/DarkGreenButton";
import { GreenButton } from "../../CSS/Outlined/GreenButton";
import { servidor } from "../../servidor";

const OrderDetails = () => {
  const dimensionHeight = useDimensions("height");
  const dimensionWidth = useDimensions("width");
  // const widthTablet = dimensionWidth > 768 && dimensionWidth < 1023;
  const Phone = dimensionWidth > 320 && dimensionWidth < 767;
  // const Ticket = widthTablet > Phone;
  const { id } = useParams();
  // Recuperamos el UID de la sesión activa desde el almacenamiento local
  const uid = localStorage.getItem("@session");
  const [order, setOrder] = useState({});
  const [boletas, setBoletas] = useState([]);
  // Cancelacion modal
  const [cancel, setCancel] = useState(false);
  const CancelClose = () => {
    setCancel(false);
  };

  const getOrder = async () => {
    const docRef = doc(db, "PedidosAux", id);
    const dataGet = await getDoc(docRef);
    setOrder({ ...dataGet.data(), ref: dataGet.ref, id: dataGet.id });
  };

  const getBoletas = async () => {
    const ref = collection(db, "PedidosAux", id, "Boletas");
    const response = await getDocs(ref);
    const boletasData = response.docs.map((doc) => {
      return {
        doc: doc.id,
        ref: doc.ref,
        ...doc.data(),
      };
    });

    setBoletas(boletasData);
  };

  console.log(order);

  const [download, setDownload] = useState(false); //spinner download

  const downloadPDF = async (id, type) => {
    try {
      // Mostrar el spinner de descarga
      setDownload(true);

      const response = await servidor.post("/api/pedidos/downloadPDF", {
        id,
        type,
        uid,
      });

      if (!response.data) {
        throw new Error("No se recibió ningún dato en la respuesta");
      }

      // Convertir el contenido del PDF a una cadena Base64
      const pdfBase64 = response.data;

      // Crear un enlace para descargar el PDF
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${pdfBase64}`;
      link.setAttribute("download", `${order.np}_${type}.pdf`);
      link.style.display = "none"; // Ocultar el enlace

      // Agregar el enlace al DOM y simular un clic para descargar el archivo
      document.body.appendChild(link);
      link.click();

      // Eliminar el enlace del DOM después de la descarga
      document.body.removeChild(link);

      // Ocultar el spinner de descarga después de la descarga completa
      setDownload(false);

      console.log("PDF descargado correctamente");
    } catch (error) {
      console.error("Error al descargar el PDF:", error.message);
      // Ocultar el spinner de descarga si ocurre un error
      setDownload(false);
    }
  };
  useEffect(() => {
    getOrder();
    getBoletas();
  }, []);

  return (
    <>
      <Grid
        container
        backgroundColor="#D9EDFF"
        style={{ filter: cancel ? "blur(4px)" : "none" }}
      >
        <Grid item xs={12} md={6} xl={7} lg={7} style={design.ColorFondo}>
          <h1 style={design.title}>PED-{order.np}</h1>
          <h2 style={design.subtitle}>
            <Dates
              widthIMG={25}
              fecha={order.services_Dates}
              fechaServicio={order.fechaServicio}
            />
            <StatusXStaff width={25} status={order.status} />
          </h2>

          {download ? (
            <>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                mb={5}
              >
                <CircularProgress />
              </Grid>
            </>
          ) : (
            <>
              <Stack direction="row" justifyContent="center">
                <Tooltip title="Descargar PDF orden">
                  <GreenButton
                    variant="outlined"
                    size="small"
                    onClick={() => downloadPDF(order.id, "MoviLabs")}
                  >
                    <Box
                      component="img"
                      alt="Descargar PDF orden"
                      src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Download.png"
                      // 1600 x 1600
                      width={43}
                      height={43}
                    />
                    <p onClick={() => downloadPDF(order.id, "MoviLabs")}>
                      Descargar recibo
                    </p>
                  </GreenButton>
                </Tooltip>
              </Stack>
            </>
          )}
          <Grid container justifyContent="center" alignItems="center">
            <CustomClientData {...order} />

            {/* CANCELAR */}

            {order.status &&
              Object.entries(order.status).map(
                ([key, value]) =>
                  value < 4 && (
                    <>
                      <Stack
                        direction={Phone ? "column" : "row"}
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        mt={4}
                        ml={Phone && "0.1%"}
                      >
                        {/* Boton para cancelar */}
                        <RedButton
                          width={Phone ? "94%" : "100%"}
                          onClick={() => setCancel(true)}
                        >
                          Cancelar servicio de {key}
                        </RedButton>
                      </Stack>
                      {/* Modal para cancelar conforme al tipo de servicio */}
                      <Modal key={key} open={cancel} onClose={CancelClose}>
                        <CancelModal pedido={order} type={key} status={value} />
                      </Modal>
                    </>
                  )
              )}

            {/* CANCELADO Tarjeta */}
            <Grid container justifyContent="center" alignItems="center">
              {order.Cancelacion &&
                Object.entries(order.Cancelacion).map(([key, doc]) => (
                  <CanceledOrder type={key} doc={doc} />
                ))}
            </Grid>

            {/* Boletas */}
            <div
              style={{
                // height: `${dimensionHeight}` - 95,
                ...design.Scroll,
              }}
            >
              {boletas.map((doc) => {
                return (
                  <TicketsPersonal
                    nombre={doc?.DatosPersonales?.nombre}
                    fechaNacimiento={doc.DatosPersonales?.fechaNacimiento}
                    edad={doc?.DatosPersonales?.edad}
                    sexo={doc?.DatosPersonales?.sexo}
                    pasaporte={doc.DatosPersonales?.pasaporte}
                    telefono={doc.DatosPersonales?.telefono}
                    correo={doc?.DatosPersonales?.correo}
                    Estudios={doc?.Estudios}
                    observaciones={doc?.observaciones}
                    ingesta={doc?.ingesta}
                    Motivo={doc?.motivo}
                    responsable={doc?.responsable}
                    fechaAlta={doc?.fechaAlta}
                  />
                );
              })}
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={5}
          xl={5}
          style={{
            backgroundColor: "#D9EDFF",
            height: Phone ? null : `${dimensionHeight}` - 90,
            padding: 20,
          }}
        >
          {/* <TableArticle /> */}
          <CustomTable botomDisable={true} order={order} />

          {/* TableHistory Mov. */}
          <TableHistory id={id} franquicia={order.franquicia} />
        </Grid>
      </Grid>
    </>
  );
};
const CancelModal = ({ pedido = [], onClose = () => {} }) => {
  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    checkedLab: false,
    checkedRad: false,
    checkedOpe: false,
    motivoLab: "",
    motivoRad: "",
    motivoOpe: "",
  });

  const cancelarPedido = async () => {
    try {
      await cancelar_Pedido(state, pedido.ref, pedido.franquicia, pedido).then(
        (response) => {
          if (response) {
            enqueueSnackbar(
              `¡Pedido ${pedido.np} cancelado correctamente :)!`,
              {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 1600,
              }
            );
            window.location.reload();
            return;
          }
        }
      );
      //await pedido.emailModificacion();
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "warning",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
    }
    onClose();
  };
  return (
    <Box sx={design.modal}>
      <Grid container justifyContent="center" alignItems="center">
        <h1 style={design.texCan}>Cancelación de servicio</h1>
        <Grid item xs={12} md={6} xl={6}>
          <FormGroup style={{ marginTop: "-1%" }}>
            {Object.entries(pedido.status).map(([key, value]) => {
              return (
                <>
                  {value < 100 ? (
                    <Cancelacion type={key} setState={setState} state={state} />
                  ) : null}
                </>
              );
            })}
          </FormGroup>
        </Grid>
        <DarkGreenButton onClick={cancelarPedido}>
          Cancelar pedido
        </DarkGreenButton>
      </Grid>
    </Box>
  );
};
export default OrderDetails;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    textAlign: "center",
    fontWeight: "500",
    color: "#00518c",
    fontSize: 30,
  },
  ColorFondo: {
    // padding: "0px 5px",
    backgroundColor: "#FFF",
    borderTopRightRadius: 40,
  },
  Scroll: {
    marginTop: "3%",
    marginBottom: "3%",
    borderRadius: 20,
    width: "95%",
  },
  subtitle: {
    textAlign: "center",
    fontWeight: "500",
    color: "#4496D2",
    fontSize: 20,
    fontFamily: "Lexend",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    border: "1px solid #c6c6c6",
    fontFamily: "Lexend",
    backgroundColor: "#FFF",
    borderRadius: 5,
    boxShadow: 24,
    padding: 5,
  },
  texCan: {
    fontWeight: "bold",
    color: "#9e0f02",
  },
};
