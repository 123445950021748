/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, InputAdornment, IconButton, Grid } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase/firebasev2";
import { DarkGreenButton } from "../CSS/Contained/DarkGreenButton";
import CustomTextField from "../Components/CustomTextField";
import CustomDialog from "../Components/CustomDialog";
import { useAuth } from "../Context/Auth_v2/Auth.context";
import { getSessionToken } from "../Controllers/getSessionToken.controller";
import { updateHistoryLogin } from "../Controllers/updateHistoryLogin";
import { servidor } from "../servidor";
import { validateToken } from "../Apis/validateToken";
import { email_Format } from "../Validations/email_Format";

/**
 * Componente funcional para la página de inicio de sesión.
 * @component
 */
const Login = (isMobile, isTabletVertical, isTabletHorizontal) => {
  const { login } = useAuth();
  /**
   * Hook que devuelve una función para la navegación programática en la aplicación.
   * @function useNavigate
   * @description Permite realizar la navegación entre rutas dentro de la aplicación sin necesidad de utilizar enlaces (links).
   * @returns {Function} Una función que se puede usar para cambiar la ubicación (ruta) actual del navegador.
   */
  const navigation = useNavigate();
  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();

  /**
   *  Estado y funciones para el formulario de inicio de sesión
   */
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    email: "",
    correo: "",
  });

  // Funciones para manejar la visibilidad de la contraseña
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Función asincrónica para iniciar sesión y obtener un token de sesión.
   * @function
   * @async
   * @returns {Promise<void>} Promesa que se resuelve después de iniciar sesión y obtener el token.
   * @throws {Error} Error de inicio de sesión o error desconocido.
   * @throws {string} Mensaje de error específico del servidor.
   * @example
   */
  const handleLogin = async () => {
    try {
      const { user } = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const { token } = await getSessionToken(user.uid);
      await localStorage.setItem("token", token);
      await login(token);
      const response = await validateToken("", user.uid);
      // console.log('response',response);
      if (user.uid && response) {
        await updateHistoryLogin(user?.uid, response.store);
      }
      enqueueSnackbar("Bienvenidx " + user?.displayName, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
      navigation("/");
    } catch (error) {
      console.error("Error auth login", error);
      let errorMessage =
        "Error desconocido. No se recibió respuesta del servidor.";

      if (error.code) {
        // Manejo de errores específicos de Firebase Authentication
        switch (error.code) {
          case "auth/invalid-email":
            errorMessage = "Correo electrónico inválido.";
            break;
          case "auth/user-disabled":
            errorMessage = "Tu cuenta ha sido deshabilitada.";
            break;
          case "auth/user-not-found":
          case "auth/wrong-password":
            errorMessage = "Correo electrónico o contraseña incorrectos.";
            break;
          case "auth/invalid-login-credentials":
            errorMessage = "Verifica las credenciales de acceso.";
            break;
          default:
            errorMessage = "Ocurrió un error al handleLogin sesión.";
            break;
        }
      } else if (error.message) {
        errorMessage = error.message;
      }

      // Cierra la sesión en caso de error
      signOut(auth);
      // Muestra el mensaje de error en español
      enqueueSnackbar(errorMessage, {
        variant: "warning",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
    }
  };

  // Modal recovery password
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Estado y función para manejar la carga y envío de correos de recuperación
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Maneja el clic del botón para enviar un correo de cambio de contraseña.
   *
   * @function handleClick
   * @description Esta función valida el formato del correo electrónico, envía una solicitud POST al servidor para el cambio de contraseña, y gestiona los mensajes de error o éxito mediante `enqueueSnackbar`.
   *
   * @param {Object} values - Objeto que contiene los valores del formulario.
   * @param {string} values.correo - El correo electrónico ingresado por el usuario.
   * @param {Function} enqueueSnackbar - Función para mostrar notificaciones.
   * @param {Function} setIsLoading - Función para gestionar el estado de carga.
   * @param {Function} handleClose - Función para cerrar el modal o diálogo actual.
   * @param {Object} servidor - Instancia del cliente HTTP (por ejemplo, Axios) para realizar solicitudes al servidor.
   *
   * @returns {void}
   */
  const handleClick = () => {
    setIsLoading(true); // Inicia el estado de carga
    try {
      // Valida el formato del correo electrónico
      email_Format(values.correo);
    } catch (error) {
      // Maneja el error de formato de correo y detiene la carga
      setIsLoading(false);
      enqueueSnackbar("Dirección de correo electrónico no válida.", {
        variant: "warning",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
      return; // Detiene la ejecución si el correo es inválido
    }

    // Realiza la solicitud POST para cambio de contraseña
    servidor
      .post("/mails/cambioContrasena", { mail: values.correo })
      .then(() => {
        // Notifica éxito y cierra el modal
        enqueueSnackbar("Correo enviado correctamente!", {
          variant: "success",
          preventDuplicate: true,
          autoHideDuration: 1600,
        });
        setIsLoading(false); // Detiene el estado de carga
        handleClose(); // Cierra el modal
      })
      .catch(() => {
        // Maneja el error de servidor y notifica
        setIsLoading(false); // Detiene el estado de carga
        enqueueSnackbar("Ocurrió un error, inténtalo nuevamente.", {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 1600,
        });
      });
  };


  // Datos de beneficios para mostrar en la página
  const benefits = [
    {
      image: "https://movicaremx.com/IMG/MoviLabs/iPad-usando-movilabs.png",
      benefit:
        "agendar tomas a domicilio o recolecciones de muestras para tus pacientes",
    },
    {
      image:
        "https://movicaremx.com/IMG/MoviLabs/Macbook-usando-movilabs-detalle-servicio.png",
      benefit:
        "dar seguimiento a las tomas de muestras o recolecciones que agendes en MoviLabs",
    },
    {
      image: "https://movicaremx.com/IMG/MoviLabs/Macbook-usando-movilabs.png",
      benefit:
        "cotizar o informar a tus pacientes sobre los servicios a domicilio que ofrezcas",
    },
    {
      image: "https://movicaremx.com/IMG/MoviLabs/iMac-usando-movilabs.png",
      benefit:
        "Brindar una atención de calidad e integral y aumentar la satisfacción de tus pacientes",
    },
  ];
  // Estado y efecto para mostrar un beneficio aleatorio

  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * benefits.length);
    setSelectedData(benefits[randomIndex]);
  }, []); // Se ejecutará una vez al montar el componente

  return (
    <Grid container style={design.root}>
      {/* Sección del formulario de inicio de sesión */}
      <Grid item xs={12} md={7} xl={7}>
        <Grid container justifyContent="center" alignItems="center">
          <Box
            width={
              isMobile || isTabletVertical || isTabletHorizontal ? "40%" : "60%"
            }
            height={
              isMobile || isTabletVertical || isTabletHorizontal ? "40%" : "60%"
            }
            sx={{
              marginTop:
                isMobile || isTabletVertical || isTabletHorizontal ? 0 : -20,
            }}
            component="img"
            // 1600 x 1600
            src="https://movicaremx.com/IMG/MoviLabs/movilabs_3000.png"
            alt="Logotipo-MoviLabs"
          />
        </Grid>
        <div style={design.centrar}>
          <CustomTextField
            name="Input-Correo-electrónico"
            title="Input-Correo-electrónico"
            label="Correo electrónico"
            value={values.email}
            onChange={(email) =>
              setValues({ ...values, email: email.target.value })
            }
            required
            type="email"
            width="70%"
          />
          <CustomTextField
            name="Input-Contraseña"
            title="Input-Contraseña"
            label="Contraseña"
            value={values.password}
            type={values.showPassword ? "text" : "password"}
            width="70%"
            required
            onChange={(password) =>
              setValues({ ...values, password: password.target.value })
            }
            onKeyPress={(event, value) => {
              if (event.code === "Enter") {
                handleLogin();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ color: "#C4C4C4" }}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <p
            style={{
              ...design.passrecovery,
              marginLeft:
                isMobile || isTabletVertical || isTabletHorizontal
                  ? "5%"
                  : "35%",
            }}
            onClick={handleClickOpen}
          >
            ¿Se te olvido tu contraseña?
          </p>
          <CustomDialog
            open={open}
            handleClose={handleClose}
            handleClick={handleClick}
            isLoading={isLoading}
            input={true}
            widthIMG={isMobile || isTabletVertical || isTabletHorizontal}
            title="Reestablecer contraseña"
            body="Ingresa el correo electrónico con el que te has registrado, en
            breve recibirás un correo para recuperar tu contraseña. No olvides
            revisar tu carpeta de spam."
            image="https://movicaremx.com/IMG/app_mobile/FormatPNG/Recovery_con.png"
            form={values.correo}
            onChange={(correo) =>
              setValues({ ...values, correo: correo.target.value })
            }
          />
          <DarkGreenButton width={"70%"} onClick={handleLogin}>
            Acceder
          </DarkGreenButton>
        </div>
      </Grid>
      {/* Sección de beneficios de Movilabs */}
      <Grid item xs={12} md={5} xl={5}>
        <h1 style={design.title}>En movilabs podrás...</h1>
        <Box
          component="img"
          src={selectedData ? selectedData.image : ""}
          alt={selectedData ? selectedData.title : ""}
          // 64 x 173
          width={
            isMobile || isTabletVertical || isTabletHorizontal ? "40%" : "60%"
          }
          height={
            isMobile || isTabletVertical || isTabletHorizontal ? "40%" : "60%"
          }
        />
        {selectedData && <h2 style={design.title}>{selectedData.benefit}</h2>}
      </Grid>
    </Grid>
  );
};

export default Login;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  root: {
    margin: 0,
    padding: 0,
    background:
      "url('https://movicaremx.com/IMG/MoviLabs/Fondo_MoviLabs.png') no-repeat center center/cover",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    minHeight: "100vh",
  },
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "left",
    marginLeft: "5%",
    marginRight: "5%",
  },
  centrar: {
    marginLeft: "25%",
    marginTop: "-10%",
  },
  passrecovery: {
    fontFamily: "Lexend",
    color: "#C4C4C4",
    fontWeight: 500,
    lineHeight: "20.01px",
    letterSpacing: "0em",
  },
};
