/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { getDoc } from "firebase/firestore";
import { dateInstant } from "../../../../Utils/dateInstant";

const ICONS = {
  laboratorista:
    "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Laboratorio.png",
  radiologo:
    "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Rayos_X.png",
  doctor:
    "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Consultas.png",
  administrador:
    "https://movicaremx.com/IMG/app_mobile/FormatPNG/Perfil/Call_center.png",
  operador:
    "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Ambulancia.png",
};

const CanceledOrder = ({ type, doc }) => {
  const [operador, setOperador] = useState("");
  const [complete, setComplete] = useState("");

  const getRef = async () => {
    const dataGet = await getDoc(doc.responsable);
    const data = dataGet.data();
    const nombre = data.DatosPersonales?.nombre;
    const apellidos = data.DatosPersonales?.apellidos;
    setOperador(nombre);
    setComplete(nombre + " " + apellidos);
  };

  const getFormattedAmount = (amount) => {
    if (amount !== null && amount !== undefined) {
      return amount.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
        currencyDisplay: "symbol",
      });
    }
    return "N/A"; // O cualquier valor predeterminado que desees mostrar para montos nulos o no definidos
  };

  

  useEffect(() => {
    getRef();
  }, []);

  return (
    <Grid container xs={12} md={6} xl={6} style={design.Align}>
      <Box
        component="img"
        src={ICONS[type]}
        // 64 x 173
        width={50}
        height={50}
      />
      <h2 style={design.title}>Cancelado por {operador}</h2>
      <Grid container style={design.Card}>
        <Grid container alignItems="center">
            <p style={design.subtitle}>
              Motivo: {doc?.motivo}
              <br />
              Monto: {getFormattedAmount(doc.amount)}
              <br />
              Fecha:{" "}
              {doc.fecha
                ? dateInstant(doc?.fecha)
                : dateInstant(doc.fechaAlta)}{" "}
              por {complete}
            </p>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CanceledOrder;
/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    color: "rgba(68, 150, 210, 1)",
    textAlign: "center",
    fontWeight: "500",
    fontSize: 20,
    alignItems: "center",
    fontFamily: "Lexend",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "90%",
    padding: 15,
    fontFamily: "Lexend",
    fontWeight: "100",
  },
  Align: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2%",
  },
};
