import React from "react";
import { Box } from "@mui/material";

const StepperOrder = ({ activity, onClick = () => {}, windowHeight }) => {
  return (
    <div style={{ paddingTop: 20 }}>
      {Stepper.map((doc, idx) => {
        const isActive = activity.includes(idx);
        const isCompleted = idx < activity.length;
        return (
          <>
            <Box style={design.BoxAlign} key={doc.id}>
              <Box
                onClick={() => onClick(idx)}
                style={{
                  backgroundColor: isActive ? "#35b32e" : "#FFF",
                  border: isActive ? null : "3px solid #35b32e",
                  ...design.Card,
                }}
              >
                <p
                  style={{
                    color: isActive
                      ? "#FFF"
                      : isCompleted
                      ? "#35b32e"
                      : "#35b32e",
                    ...design.text,
                  }}
                >
                  {doc.title}
                </p>
              </Box>
            </Box>
            <Box style={design.BoxAlign} key={`${doc.id}-line`}>
              {idx !== 4 && (
                <div style={{ height: windowHeight * 0.05, ...design.Linea }} />
              )}
            </Box>
          </>
        );
      })}
    </div>
  );
};

export default StepperOrder;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  BoxAlign: {
    display: "flex",
    justifyContent: "center",
  },
  Linea: {
    border: "2px dashed #C5C5C5",
    textAlign: "center",
    marginTop: 9,
    marginBottom: 9,
  },
  text: {
    textAlign: "center",
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 17,
    fontWeight: "500",
    lineHeight: "118%",
  },
  Card: {
    width: 120,
    height: 60,
    borderRadius: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const Stepper = [
  { id: 0, title: "Datos de contacto" },
  { id: 1, title: "Dirección" },
  { id: 2, title: "Estudios" },
  { id: 3, title: "Método de pago" },
  { id: 4, title: "Horario del servicio" },
];
