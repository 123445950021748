/**
 * Returns an object containing stores.
 * @returns {Object.<string, string>} Object containing store keys and their corresponding values.
 */
export const castStore = () => {
    let stores = {
        X1mFXnBjf48uFNWFB9ow: "Laboratorio Clínico Durán",
        ihnZXN27LSXT1OjDDqM6: "Tu empresa",
        pNtt17TB92KPMuaFAFc1: "CAEMII",
        uaE7pI9DqKoAUYDyI05w: "dclin Laboratorio Clínico",
        tf2tw1JlFKXXOizQ1Y3IDdkN3Wu1: "Laboratorios Rosalind",
        "5GwQ0ngA2Cm3njjqhGVx":"Sendia Genetics",
    };
    return stores;
}
