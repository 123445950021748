/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Personal from "../../Components/Ordenes/Personal";
import Dates from "../../Components/Ordenes/Dates";
import StripedGrid from "../../Components/StripeDataGrid";
import Origin from "../../Components/Ordenes/Origin";
import StatusXStaff from "../../Components/Ordenes/StatusXStaff";
import { CircularProgress, Stack } from "@mui/material";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import { validateToken } from "../../Apis/validateToken";
import {
  collection,
  where,
  query,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase/firebasev2";
import timeStamp_toString from "../../Utils/timeStamp_toString";
import { castStatus } from "../../Utils/castStatus";

const ViewOrders = () => {
  const navigate = useNavigate();
  const [pedidos, setPedididos] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token, user } = useAuth();
  let franquicia = null;
  let sucursal = null;
  const clickArow = (row) => {
    navigate("/order-details/" + row.id);
  };

  const getPedidos = async () => {
    setLoading(true);
    if (!token || !user?.uid) return null;
    const response = await validateToken(token, user?.uid);

    if (typeof response != "object") {
      const response = await validateToken(token, user.uid);
      return response;
    }
    
    franquicia = response.store;
    sucursal = response.branch;

    const query = getQuery("laboratorista");
    const query1 = getQuery("radiologo");
    const query2 = getQuery("administrador");
    const query3 = getQuery("operador");

    const array = await constructData(query);
    const array1 = await constructData(query1);
    const array2 = await constructData(query2);
    const array3 = await constructData(query3);

    let fixPedidos = [...array, ...array1, ...array2, ...array3];
    const orders = fixPedidos.filter((obj, index) => {
      return index === fixPedidos.findIndex((o) => obj.np === o.np);
    });

    setPedididos(orders);
  };

  const getQuery = (type) => {
    const ref = collection(db, "PedidosAux");
    return query(
      ref,
      where("status." + type, "<=", 10),
      where("franquicia", "==", franquicia),
      where("sucursal", "==", sucursal)
    );
  };

  const constructData = async (query) => {
    const array = await getDocs(query);
    setLoading(false);
    const order = array.docs.map(async (doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
        origin: await getOrigen(doc.data().origen, doc.data().franquicia),
        respoName: await getDatosPersonalesNombre(doc.data().responsable),
        laboratoryName: await getDatosPersonalesNombre(
          doc.data().laboratorista
        ),
        radiologistName: await getDatosPersonalesNombre(doc.data().radiologo),
        adminName: await getDatosPersonalesNombre(doc.data().administrador),
        operadorName: await getDatosPersonalesNombre(doc.data().operador),
      };
    });
    return await Promise.all(order);
  };
  const getDatosPersonalesNombre = async (row) => {
    if (row) {
      const getData = await getDoc(row);
      const data = await getData.data();
      return data?.DatosPersonales?.nombre;
    }
    return null;
  };
  const getOrigen = async (origen, franquicia, np) => {
    if (typeof origen === "string") {
      return `${"MoviCare"}-${origen}`;
    }
    if (origen && franquicia) {
      const origenGet = await getDoc(origen);
      const data = origenGet.data();
      const reference = doc(db, "Franquicias", franquicia);
      const getReference = await getDoc(reference);
      const dataFranquicia = getReference.data();
      return dataFranquicia.Nombre + "-" + data.Nombre;
    }
  };
  useEffect(() => {
    getPedidos();
  }, [token, user]);

  const RESPONSABLES = {
    laboratorista: "Laboratorista",
    radiologo: "Radiologo",
    administrador: "Atencion a clientes",
    operador: "Operador",
  };

  const columns = [
    {
      field: "np",
      headerName: "Folio",
      width: 180,
      renderHeader: () => <p style={design.headerTable}>Folio</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { np = 0 } = row;
        return "PED-" + np;
      },
    },
    {
      field: "DatosCliente.nombre",
      headerName: "Nombre",
      width: 250,
      renderHeader: () => <p style={design.headerTable}>Nombre</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { nombre = "" } = row.DatosCliente;
        return nombre.toUpperCase();
      },
    },
    {
      field: "DatosCliente.telefono",
      headerName: "Teléfono",
      width: 140,
      renderHeader: () => <p style={design.headerTable}>Teléfono</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { telefono } = row.DatosCliente;
        return telefono;
      },
    },
    {
      field: "services_Dates",
      headerName: "Fecha de Servicio",
      width: 300,
      valueFormatter: ({ row }) => {
        if (row.services_Dates.laboratorio) {
          return `${"Laboratorio:"} ${timeStamp_toString(
            row?.services_Dates?.laboratorio
          )}`;
        }
        if (row.services_Dates.operador) {
          return `${"Servicios:"} ${timeStamp_toString(
            row?.services_Dates?.operador
          )}`;
        }
        return `${"RayosX:"}${timeStamp_toString(
          row?.services_Dates?.radiologia
        )}`;
      },

      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Fecha de servicio</p>,
      renderCell: ({ row }) => {
        return (
          <>
            <div style={design.columnList}>
              <Dates
                widthIMG={25}
                fecha={row.services_Dates}
                fechaServicio={row.fechaServicio}
              />
            </div>
          </>
        );
      },
    },
    {
      field: "direccion",
      headerName: "Dirección de servicio",
      width: 250,
      headerAlign: "center",
      renderHeader: () => (
        <p style={design.headerTable}>Dirección de servicio</p>
      ),
      valueFormatter: ({ row }) => {
        const {
          Direccion: { ciudad, delegacion, estado },
        } = row;
        return `${ciudad},${delegacion},${estado}`;
      },
    },

    {
      field: "status",
      headerName: "Estatus",
      width: 280,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { status } = row;

        if (status) {
          const fecha = Object.entries(status).map(([key, value]) => {
            return `${RESPONSABLES[key]}` + ":" + `${castStatus(value)}`;
          });
          return fecha.join(<br />);
        }
      },
      renderHeader: () => <p style={design.headerTable}>Estatus</p>,
      renderCell: ({ row }) => {
        if (!row.status) {
          return (
            <>
              <StatusXStaff estatus={row.estatus} width={25} />
            </>
          );
        }
        return (
          <div style={design.columnList}>
            <StatusXStaff width={25} status={row.status} />
          </div>
        );
      },
    },
    {
      field: "responsable",
      headerName: "Personal",
      width: 200,
      headerAlign: "center",
      valueGetter: ({ row }) => {
        const responsables = [];
        if (!!row.radiologistName) {
          responsables.push("Radiologo:" + row.radiologistName);
        }
        if (!!row.laboratoryName) {
          responsables.push("Laboratorista:" + row.laboratoryName);
        }
        if (!!row.adminName) {
          responsables.push("Atención a clientes:" + row.adminName);
        }
        if (!!row.respoName) {
          responsables.push("Responsable:" + row.respoName);
        }
        if (!!row.operadorName) {
          responsables.push("Operador:" + row.operadorName);
        }
        return responsables.join(" ");
      },
      renderHeader: () => <p style={design.headerTable}>Personal</p>,
      renderCell: ({ row }) => (
        <>
          <div style={design.columnList}>
            <Personal
              responsables={{
                laboratorista: row?.laboratorista || null,
                radiologo: row?.radiologo || null,
                administrador: row?.administrador || null,
                responsable: row?.responsable || null,
                operador: row?.operador || null,
              }}
            />
          </div>
        </>
      ),
    },
    {
      field: "origen",
      headerName: "Origen",
      width: 180,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Origen</p>,
      renderCell: ({ row }) => (
        <Origin
          franquicia={row?.franquicia}
          sucursal={row?.sucursal}
          origen={row?.origen}
        />
      ),
      valueFormatter: ({ row }) => {
        return row.origin;
      },
    },
  ];
  if (loading && !pedidos.length) {
    return (
      <>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt={25}
        >
          <CircularProgress />
        </Stack>
      </>
    );
  }
  return (
    <>
      <Stack flex={1} direction="column">
        <h1 style={design.title}>Visualizar órdenes</h1>
        <StripedGrid
          loading={false}
          columns={columns}
          rows={pedidos}
          onRowClick={(row) => clickArow(row.row)}
          pageSize={10}
          oddBackgroundColor="#E6E6E640"
          evenBackgroundColor="#65B32E40"
          oddOpacity={0.7}
          w="93%"
        />
      </Stack>
    </>
  );
};

export default ViewOrders;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  headerTable: {
    fontSize: 16,
    color: "#00518c",
    fontFamily: "Lexend",
    justifyContent: "center",
  },
  columnList: {
    whiteSpace: "pre-wrap",
    lineHeight: "1.5%",
    margin: 10,
  },
};
