import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Componente funcional que desplaza la ventana hacia la parte superior
 * cuando cambia la ruta actual (pathname).
 *
 * @function ScrollToTop
 * @description Este componente escucha los cambios en la ubicación (ruta) de la aplicación
 * utilizando `useLocation` y, cuando el pathname cambia, se desplaza suavemente
 * hacia la parte superior de la página.
 *
 * @returns {null} Este componente no renderiza ningún contenido.
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  /**
   * Desplaza la ventana hacia la parte superior de manera suave cuando cambia el pathname.
   *
   * @function
   * @memberof useEffect
   */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]); // Ejecuta el efecto cuando cambia el pathname

  return null; // No necesitas renderizar nada
};

export default ScrollToTop;
