/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  CircularProgress,
  Grid,
  Box,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { castMonth } from "../Utils/castMonth";
import { servidor } from "../servidor";
import { useSnackbar } from "notistack";
import { validateToken } from "../Apis/validateToken";
import { useAuth } from "../Context/Auth_v2/Auth.context";
import { useScreenSize } from "../hooks/useScreenSize";

/**
 * Componente Podiums.
 * Muestra una lista de pedidos agendados por sucursal al mes.
 * El componente muestra un podio con las sucursales que realizaron más pedidos.
 * @param {Object} store - Objeto que contiene la información de la tienda.
 * @returns {JSX.Element} Componente de React.
 */
const Podiums = () => {
  /**
   * Hook que proporciona el tamaño de pantalla actual y devuelve
   * indicadores booleanos para diferentes tamaños de dispositivos.
   *
   * @typedef {Object} ScreenSize
   * @property {boolean} isMobile - Indica si el tamaño de pantalla corresponde a un dispositivo móvil.
   * @property {boolean} isTabletVertical - Indica si el tamaño de pantalla corresponde a una tableta en orientación vertical.
   * @property {boolean} isTabletHorizontal - Indica si el tamaño de pantalla corresponde a una tableta en orientación horizontal.
   * @returns {ScreenSize} Un objeto que contiene los valores booleanos para isMobile, isTabletVertical, e isTabletHorizontal.
   */

  const { isMobile, isTabletVertical, isTabletHorizontal, isDesktop } =
    useScreenSize();
  // Estado para almacenar la franquicia
  const [store, setStore] = useState(true);
  const { token, user } = useAuth();

  const getFranq = async () => {
    const response = await validateToken(token, user.uid);
    setStore(response.store);
  };

  const { enqueueSnackbar } = useSnackbar();
  const [loadingOfMonth, setloadingOfMonth] = useState(false);
  const [loadingStaffRanking, setStaffRanking] = useState(false);
  const [loadingLastMonth, setloadingLastMonth] = useState(false);
  const [pedidosXStorelMonthlyAfter, setpedidosXStorelMonthlyAfter] = useState(
    {}
  );
  const [pedidosXStorelMonthly, setPedidosXStorelMonthly] = useState({});
  const [pedidosXStoreResponsible, setPedidosXStoreResponsible] = useState({});

  const getOrdersLastMonth = async () => {
    setloadingLastMonth(true);
    try {
      const responseGet = await servidor.get("/movilabs/getOrdersLastMonth/", {
        params: { store: store },
      });
      setpedidosXStorelMonthlyAfter(responseGet.data.data);
      setloadingLastMonth(false);
    } catch (error) {
      setloadingLastMonth(false);
      if (!error.response) {
        // Error de red u otro error no relacionado con la respuesta HTTP
        if (error.message.includes("Network")) {
          enqueueSnackbar(
            "No pudimos conectarnos a los servicios de MoviCare",
            {
              variant: "warning",
              autoHideDuration: 1000,
              preventDuplicate: true,
            }
          );
        } else {
          enqueueSnackbar(error.message, {
            variant: "warning",
            autoHideDuration: 1000,
            preventDuplicate: true,
          });
        }
        return;
      }

      // Error con respuesta HTTP
      const { status, data } = error.response;

      if (status === 400 || status === 401) {
        // Errores 400 y 401: muestra el mensaje de error proporcionado por el servidor
        enqueueSnackbar(data.message, {
          variant: "warning",
          autoHideDuration: 1000,
          preventDuplicate: true,
        });
      } else {
        // Otros códigos de error HTTP: muestra un mensaje genérico de error
        enqueueSnackbar(data.message || "Error desconocido", {
          variant: "warning",
          autoHideDuration: 1000,
          preventDuplicate: true,
        });
      }
    }
  };

  const getOrdersOfTheMonth = async () => {
    setloadingOfMonth(true);
    try {
      const responseGet = await servidor.get("/movilabs/getOrdersOfTheMonth/", {
        params: { store: store },
      });
      setPedidosXStorelMonthly(responseGet.data.data); // Recuerda que es un JSON
    } catch (error) {
      console.log("ERROR_GETORDERS_OFTHEMONTH", error);

      if (!error.response) {
        // Error de red u otro error no relacionado con la respuesta HTTP
        if (error.message.includes("Network")) {
          enqueueSnackbar(
            "No pudimos conectarnos a los servicios de MoviCare",
            {
              variant: "warning",
              autoHideDuration: 1000,
              preventDuplicate: true,
            }
          );
        } else {
          enqueueSnackbar(error.message, {
            variant: "warning",
            autoHideDuration: 1000,
            preventDuplicate: true,
          });
        }
        return;
      }

      // Error con respuesta HTTP
      const { status, data } = error.response;

      if (status === 400 || status === 401) {
        // Errores 400 y 401: muestra el mensaje de error proporcionado por el servidor
        enqueueSnackbar(data.message, {
          variant: "warning",
          autoHideDuration: 1000,
          preventDuplicate: true,
        });
      } else {
        // Otros códigos de error HTTP: muestra un mensaje genérico de error
        enqueueSnackbar(data.message || "Error desconocido", {
          variant: "warning",
          autoHideDuration: 1000,
          preventDuplicate: true,
        });
      }
    } finally {
      setloadingOfMonth(false); // Asegúrar que termine la carga
    }
  };

  const getStaffRanking = async () => {
    setStaffRanking(true);
    try {
      const responseGet = await servidor.get("/movilabs/getStaffRanking/", {
        params: { store: store },
      });

      setPedidosXStoreResponsible(responseGet.data.data);
      setStaffRanking(false);
    } catch (error) {
      setStaffRanking(false);
      console.log("ERROR_GETSTAFF_RANKING", error);
      if (!error.response) {
        // Error de red u otro error no relacionado con la respuesta HTTP
        if (error.message.includes("Network")) {
          enqueueSnackbar(
            "No pudimos conectarnos a los servicios de MoviCare",
            {
              variant: "warning",
              autoHideDuration: 1000,
              preventDuplicate: true,
            }
          );
        } else {
          enqueueSnackbar(error.message, {
            variant: "warning",
            autoHideDuration: 1000,
            preventDuplicate: true,
          });
        }
        return;
      }

      // Error con respuesta HTTP
      const { status, data } = error.response;

      if (status === 400 || status === 401) {
        // Errores 400 y 401: muestra el mensaje de error proporcionado por el servidor
        enqueueSnackbar(data.message, {
          variant: "warning",
          autoHideDuration: 1000,
          preventDuplicate: true,
        });
      } else {
        // Otros códigos de error HTTP: muestra un mensaje genérico de error
        enqueueSnackbar(data.message || "Error desconocido", {
          variant: "warning",
          autoHideDuration: 1000,
          preventDuplicate: true,
        });
      }
    }
  };

  useEffect(() => {
    getOrdersLastMonth();
    getOrdersOfTheMonth();
    getStaffRanking();
  }, [store]);

  // Función para obtener los pedidos al cargar el componente o al cambiar el token o usuario
  useEffect(() => {
    getFranq();
  }, [user, token]);
  // Ordenar datos de pedidos por sucursal mensualmente
  const sortedPedidosPorSucursalMonthly = pedidosXStorelMonthly
    ? Object.entries(pedidosXStorelMonthly)
        .sort(([, cantidadA], [, cantidadB]) => cantidadB - cantidadA)
        .map(([sucursalId, cantidad]) => ({ sucursalId, cantidad }))
    : [];
  // Ordenar datos de pedidos por sucursal mes anterior
  const sortedPedidosPorSucursalMonthlyAfter = pedidosXStorelMonthlyAfter
    ? Object.entries(pedidosXStorelMonthlyAfter)
        .sort(([, cantidadA], [, cantidadB]) => cantidadB - cantidadA)
        .map(([sucursalId, cantidad]) => ({ sucursalId, cantidad }))
    : [];

  // Colores para el podium
  const colors = ["#FFD700", "#C0C0C0", "#CD7F32"]; // Oro, Plata, Bronce

  const generateListItems = (pedidosXStoreResponsible, colors) => {
    // Crear un objeto para almacenar las sumas de cantidades por nombre y sucursal
    const sumasPorNombreYSucursal = {};

    // Iterar sobre el objeto de pedidosXStoreResponsible
    Object.entries(pedidosXStoreResponsible).forEach(
      ([sucursalResponsable, cantidad]) => {
        const [sucursal, responsable] = sucursalResponsable.split("-");
        if (!responsable) return; // Validar que se haya podido extraer el responsable

        if (!sumasPorNombreYSucursal[responsable]) {
          sumasPorNombreYSucursal[responsable] = {
            sucursales: [sucursal],
            cantidad: cantidad,
          };
        } else {
          sumasPorNombreYSucursal[responsable].sucursales.push(sucursal);
          sumasPorNombreYSucursal[responsable].cantidad += cantidad;
        }
      }
    );

    const sumasArray = Object.entries(sumasPorNombreYSucursal);
    sumasArray.sort((a, b) => b[1].cantidad - a[1].cantidad);

    if (!sumasArray.length) {
      return (
        <ListItem>
          <ListItemText primary="No hay datos disponibles" />
        </ListItem>
      );
    }

    return sumasArray.map(([responsable, { sucursales, cantidad }], index) => (
      <ListItem disablePadding key={responsable}>
        <ListItemButton>
          <ListItemIcon>
            <StarIcon
              style={{
                color:
                  index < colors.length ? colors[index] : `rgb(231, 231, 231)`,
              }}
            />
          </ListItemIcon>
          <ListItemText
            primary={`${cantidad} pedidos por ${responsable}`}
            secondary={`Sucursales: ${sucursales.join(", ")}`}
            primaryTypographyProps={design.title}
            secondaryTypographyProps={design.subtitle}
          />
        </ListItemButton>
      </ListItem>
    ));
  };

  const date = new Date();
  const monthBefore = date.getMonth() - 1;
  const month = date.getMonth();

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        style={{
          marginTop: "1%",
          justifyContent: "center",
          alignItems: 'center'
        }}
      >
        <h1 style={{...design.title,textAlign:'center'}}>
          <Box
            component="img"
            src="https://movicaremx.com/IMG/MoviTeam/PersonalRecord.png"
            alt="Podiums"
            width={
              isMobile
                ? "15%"
                : isTabletVertical
                ? "20%"
                : isTabletHorizontal
                ? "25%"
                : isDesktop
                ? "30%"
                : "35%"
            }
            height={
              isMobile
                ? "15%"
                : isTabletVertical
                ? "20%"
                : isTabletHorizontal
                ? "25%"
                : isDesktop
                ? "30%"
                : "35%"
            }
          />
          Estadísticas
        </h1>
      </Stack>
      <Stack
        direction={isMobile ? "column" : "row"}
        style={{
          marginY: "2%",
          justifyContent: "center",
        }}
      >
        <List
          sx={design.listRoot}
          aria-label="Podium sucursales"
          dense={true}
          subheader={
            <ListSubheader
              style={design.title}
              component="div"
              id="nested-list-subheader"
            >
              Pedidos agendados {castMonth[monthBefore]}
            </ListSubheader>
          }
        >
          {loadingLastMonth ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {sortedPedidosPorSucursalMonthlyAfter.length > 0 ? (
                sortedPedidosPorSucursalMonthlyAfter.map(
                  ({ sucursalId, cantidad }, index) => (
                    <ListItem disablePadding key={sucursalId}>
                      <ListItemButton>
                        <ListItemIcon>
                          <StarIcon
                            style={{
                              color:
                                index < 3 ? colors[index] : `rgb(231,231,231`,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${sucursalId}: ${cantidad} pedidos`}
                          primaryTypographyProps={design.subtitle}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                )
              ) : (
                <>
                  <Box
                    component="img"
                    src="https://movicaremx.com/IMG/administrador/404_icon.png"
                    alt="Vacío"
                    // 375 x 375
                    width={isMobile ? "15%" : "20%"}
                    height={isMobile ? "15%" : "26%"}
                    sx={{ ml: "30%" }}
                  />{" "}
                  <p style={design.subtitle}>
                    Aún no existen las métricas suficientes.
                  </p>
                </>
              )}
            </>
          )}
        </List>
        <List
          sx={design.listRoot}
          aria-label="Podium sucursales"
          dense={true}
          subheader={
            <ListSubheader
              style={design.title}
              component="div"
              id="nested-list-subheader"
            >
              Pedidos agendados {castMonth[month]}
            </ListSubheader>
          }
        >
          {loadingOfMonth ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {sortedPedidosPorSucursalMonthly.length > 0 ? (
                sortedPedidosPorSucursalMonthly.map(
                  ({ sucursalId, cantidad }, index) => (
                    <ListItem disablePadding key={sucursalId}>
                      <ListItemButton>
                        <ListItemIcon>
                          <StarIcon
                            style={{
                              color:
                                index < 3 ? colors[index] : `rgb(231,231,231)`,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${sucursalId}: ${cantidad} pedidos`}
                          primaryTypographyProps={design.subtitle}
                        />
                      </ListItemButton>
                    </ListItem>
                  )
                )
              ) : (
                <>
                  <Box
                    component="img"
                    src="https://movicaremx.com/IMG/administrador/404_icon.png"
                    alt="Vacío"
                    // 507 x 508
                    width={isMobile ? "15%" : "20%"}
                    height={isMobile ? "15%" : "26%"}
                    sx={{ ml: "30%" }}
                  />{" "}
                  <p style={design.subtitle}>
                    Aún no existen las métricas suficientes.
                  </p>
                </>
              )}
            </>
          )}
        </List>
        <List
          sx={{ ...design.listRoot, height: 250 }}
          aria-label="Podium responsables"
          dense={true}
          subheader={
            <ListSubheader
              style={design.title}
              component="div"
              id="nested-list-subheader"
            >
              Pedidos {castMonth[month]} por ATC
            </ListSubheader>
          }
        >
          {loadingStaffRanking ? (
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {Object.keys(pedidosXStoreResponsible).length > 0 ? (
                generateListItems(pedidosXStoreResponsible, colors)
              ) : (
                <>
                  <Box
                    component="img"
                    src="https://movicaremx.com/IMG/administrador/404_icon.png"
                    alt="Vacío"
                    width={isMobile ? "15%" : "20%"}
                    height={isMobile ? "15%" : "26%"}
                    sx={{ ml: "30%" }}
                  />
                  <p style={design.subtitle}>
                    Aún no existen las métricas suficientes.
                  </p>
                </>
              )}
            </>
          )}
        </List>
      </Stack>
    </>
  );
};

export default Podiums;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  listRoot: {
    width: "100%",
    maxWidth: 400,
    position: "relative",
    overflow: "auto",
    fontFamily: "Lexend",
  },
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    letterSpacing: "0em",
  },
  subtitle: {
    fontSize: "0.7em",
    fontFamily: "Lexend",
  },
};
