/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Avatar, CircularProgress, Grid, Tooltip } from "@mui/material";
import { Apple, Language, Android } from "@mui/icons-material";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebasev2";

const CustomToolTip = ({ type }) => {
  return (
    <>
      {type === "administrador" && (
        <Tooltip
          arrow
          title={"Movicare administrador" || "Verificar información"}
        >
          <Avatar
            sx={{ width: 35, height: 35 }}
            src="https://movicaremx.com/IMG/BACKGROUNDS/MoviCare_Circulo.png"
            alt="Movicare administrador"
          />
        </Tooltip>
      )}
      {type === "web" && (
        <Tooltip arrow title={"Movicare web" || "Verificar información"}>
          <Language style={{ color: "#2e4666" }} />
        </Tooltip>
      )}

      {type === "android" && (
        <Tooltip arrow title={"Movicare android" || "Verificar información"}>
          <Android style={{ color: "#35b32e" }} />
        </Tooltip>
      )}
      {type === "ios" && (
        <Tooltip arrow title={"Movicare ios" || "Verificar información"}>
          <Apple style={{ color: "#b4b4b4" }} />
        </Tooltip>
      )}
    </>
  );
};

const Origin = ({ franquicia, origen, sucursal, ...rest }) => {
  const [infOrigen, setInfOrigen] = useState(null);
  const [infoFranquicia, setInfofranquicia] = useState();
  const [loading, setLoading] = useState(true);

  const getOrigen = async () => {
    try {
      if (typeof origen === "string") {
        setInfOrigen(origen);
        setLoading(false);
      } else if (origen._path && origen._path.segments.length === 4) {
        const ref = doc(
          db,
          origen._path.segments[0], // Colección principal
          origen._path.segments[1], // ID del documento en la colección principal
          origen._path.segments[2], // Subcolección
          origen._path.segments[3] // ID del documento en la subcolección
        );
        const data = await getDoc(ref);

        if (!data.exists()) {
          throw new Error("No fue posible obtener el origen desde _path.");
        }

        setInfOrigen({ ...data.data() });
        setLoading(false);
      } else {
        const getOrigen = await getDoc(origen);
        if (!getOrigen.exists) {
          throw new Error("No fue posible obtener el origen.");
        }
        const data = getOrigen.data();

        setInfOrigen({ ...data });
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("Error obteniendo el origen:", error);
      setLoading(false);
    }
  };

  const getFranquicia = async () => {
    if (franquicia) {
      const reference = doc(db, "Franquicias", franquicia);
      const getReference = await getDoc(reference);
      if (!getReference.exists) {
        throw new Error("No fue posible obtener la franquicia");
      }
      const data = getReference.data();
      setInfofranquicia({ ...data });
      setLoading(false);
    }
    return;
  };
  useEffect(() => {
    getOrigen();
    getFranquicia();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      {infoFranquicia && infOrigen && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip
            arrow
            title={infoFranquicia.Nombre || "Verificar información"}
          >
            <Avatar
              sx={{ width: 35, height: 35 }}
              src={infoFranquicia.avatar}
              alt={infoFranquicia.avatar}
            />
          </Tooltip>{" "}
          &nbsp;&nbsp;
          {`${infOrigen?.Nombre}` ?? "Verificar información"}
        </Grid>
      )}
      {!infoFranquicia && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <CustomToolTip type={infOrigen?.Nombre || infOrigen} /> &nbsp;&nbsp;
          {infOrigen?.Nombre}
        </Grid>
      )}
    </>
  );
};

export default Origin;
