/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomAutocomplete from "../../Components/CustomAutocomplete";
import { validateToken } from "../../Apis/validateToken";
import { getBranches } from "../../Apis/getSucursales";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import { DarkBlueButton } from "../../CSS/Contained/DarkBlueButton";
import { servidor } from "../../servidor";
import StripedGrid from "../StripeDataGrid";

/**
 * Componente para importar datos CSV y enviarlos a una API.
 * @returns {JSX.Element} Componente React.
 */
const ImportCSV = () => {
   /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvData, setCsvData] = useState([]); //estado que almacena los estudios
  const [sucursales, setSucursales] = useState([]); // Estado para almacenar las sucursales
  const [selectedBranch, setSelectedBranch] = useState(""); //estado que almacena la sucursal a la que se dan de alta los estudios
  const [branch, setBranch] = useState("");
  const [store, setStore] = useState("");
  const { token, user } = useAuth();

  /**
   * Maneja la carga de archivos seleccionados por el usuario.
   * @param {Event} event - Evento del cambio de archivo.
   */
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      enqueueSnackbar("No se ha seleccionado ningún archivo", {
        variant: "warning",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
      return;
    }

    const fileName = file.name;
    const fileType = fileName.split(".").pop().toLowerCase();

    if (fileType !== "csv") {
      enqueueSnackbar("El archivo seleccionado no es un archivo CSV", {
        variant: "warning",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
      return;
    }

    setSelectedFile(file);
  };

  // Aqui es donde trae el store y el branch del validate token y el user.uid
  useEffect(() => {
    if (user?.uid && token) {
      validateToken(token, user?.uid)
        .then(({ store, branch }) => {
          setBranch(branch);
          setStore(store);
        })
        .catch(console.log);
    }
  }, [token, user]);

  useEffect(() => {
    if (user?.uid && token) {
      getBranches(user?.uid, token).then(setSucursales);
    }
  }, [token, user]);

  /**
   * Maneja el cambio de sucursal seleccionada.
   * @param {Object} value - Objeto de la sucursal seleccionada.
   */
  const handleBranchChange = (value) => {
    setSelectedBranch(value?.id);
  };

  // Aqui se hace la carga del archivo
  const handleOnSubmit = () => {
    if (selectedFile) {
      const fileReader = new FileReader();

      fileReader.onload = function (event) {
        const text = event.target.result;
        const data = processData(text);

        if (data.length === 0) {
          enqueueSnackbar(
            "El archivo seleccionado está vacío o no contiene datos válidos",
            {
              variant: "warning",
              preventDuplicate: true,
              autoHideDuration: 1600,
            }
          );
          return;
        }

        setCsvData(data);
        console.log("data", data);
        enviarDatosApi(data);
      };

      fileReader.onerror = function (error) {
        console.error("Error al leer el archivo:", error);
        enqueueSnackbar(
          "Error al leer el archivo. Por favor, inténtalo de nuevo más tarde.",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 1600,
          }
        );
      };

      fileReader.onerror = function (error) {
        console.error("Error al leer el archivo:", error);
        enqueueSnackbar("Error al leer el archivo", {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 1600,
        });
      };

      fileReader.readAsText(selectedFile);
    } else {
      enqueueSnackbar("No se ha seleccionado ningún archivo", {
        variant: "warning",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
    }
  };

  /**
   * Procesa los datos de texto y devuelve un array de objetos.
   * @param {string} text - El texto de entrada que contiene valores separados por comas.
   * @returns {Array<Object>} Un array de objetos donde cada objeto representa una fila de datos.
   */
  const processData = (text) => {
    const lines = text.split("\n");
    const headers = lines[0].split(",").map((header) => header.trim());

    return lines.slice(1).map((line) => {
      const values = parseCSVLine(line);
      const rowData = {};

      headers.forEach((header, index) => {
        let value = values[index] ? values[index].trim() : "";
        rowData[header] = value;
      });

      return rowData;
    });
  };

  // Función para analizar una línea de CSV, teniendo en cuenta las comillas dobles
  const parseCSVLine = (line) => {
    const values = [];
    let current = "";
    let insideQuotes = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];
      if (char === '"') {
        // Cambiar el estado de'insideQuotes cuando se encuentran comillas dobles
        insideQuotes = !insideQuotes;
      } else if (char === "," && !insideQuotes) {
        // Si encontramos una coma y no estamos dentro de comillas dobles, agregamos el valor actual
        values.push(current);
        current = ""; // Restablecer el valor actual para el próximo valor
      } else {
        current += char; // Agregar el carácter actual al valor actual
      }
    }

    // Agregar el último valor después del bucle
    values.push(current);
    return values;
  };

  /**
   * Envía los datos a la API.
   * @param {Array<Object>} data - Datos a enviar.
   * @param {string} uid - Identificador de usuario.
   */
  const enviarDatosApi = async (data, uid) => {
    try {
      const response = await servidor.post(
        `/movilabs/store/${store}/branches/${selectedBranch}`,
        data,
        {
          headers: {
            uid: user.uid,
          },
        }
      );
      console.log("Respuesta de la API:", response.data);
      enqueueSnackbar("Datos enviados exitosamente", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
    } catch (error) {
      console.error("Error al enviar datos a la API:", error);
      enqueueSnackbar("Error al enviar datos a la API", {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
    }
  };

  return (
    <>
      <h1 style={design.title}>Recolección de Productos</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} xl={4}>
          <CustomAutocomplete
            options={sucursales}
            getOptionLabel={(option) => option.name}
            value={selectedBranch}
            required={true}
            onChange={handleBranchChange}
            renderOption={(props, option) => <p {...props}>{option.name}</p>}
          ></CustomAutocomplete>
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <input type="file" onChange={handleFileUpload} />
        </Grid>
        <Grid item xs={12} md={4} xl={4}>
          <DarkBlueButton onClick={handleOnSubmit}>
            Importar Archivo
          </DarkBlueButton>
        </Grid>
        {csvData.length && (
          <Grid item xs={12} md={12} xl={12}>
            <StripedGrid
              loading={false}
              columns={columns}
              rows={csvData.map((row) => ({ ...row, id: row.Clave }))}
              pageSize={25}
              oddBackgroundColor="#E6E6E640"
              evenBackgroundColor="#CCCCCC66"
              oddOpacity={0.7}
              w="93%"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ImportCSV;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    textAlign: "center",
  },
};

const columns = [
  {
    field: "Clave",
    headerName: "Clave",
    width: 180,
    renderHeader: () => <p style={design.headerTable}>Clave</p>,
    headerAlign: "center",
  },
  {
    field: "Costo",
    headerName: "Costo",
    width: 100,
    renderHeader: () => <p style={design.headerTable}>Costo</p>,
    headerAlign: "center",
    valueFormatter: ({ row }) => {
      return `${row.Costo.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
        currencyDisplay: "symbol",
      })}`;
    },
  },
  {
    field: "Nombre",
    headerName: "Nombre",
    width: 140,
    renderHeader: () => <p style={design.headerTable}>Nombre</p>,
    headerAlign: "center",
  },
  {
    field: "Resultados",
    headerName: "Resultados",
    width: 250,
    headerAlign: "center",
    renderHeader: () => <p style={design.headerTable}>Resultados</p>,
  },
  {
    field: "Departamento",
    headerName: "Departamento",
    width: 250,
    headerAlign: "center",
    renderHeader: () => <p style={design.headerTable}>Departamento</p>,
  },
  // {
  //   field: "Descripcion",
  //   headerName: "Descripcion",
  //   width: 250,
  //   headerAlign: "center",
  //   renderHeader: () => <p style={design.headerTable}>Descripcion</p>,
  // },
  {
    field: "Subdepartamento",
    headerName: "Subdepartamento",
    width: 250,
    headerAlign: "center",
    renderHeader: () => <p style={design.headerTable}>Subdepartamento</p>,
  },
  // {
  //   field: "Requisitos",
  //   headerName: "Requisitos",
  //   width: 250,
  //   headerAlign: "center",
  //   renderHeader: () => <p style={design.headerTable}>Requisitos</p>,
  // },
  // {
  //   field: "Recomendaciones",
  //   headerName: "Recomendaciones",
  //   width: 250,
  //   headerAlign: "center",
  //   renderHeader: () => <p style={design.headerTable}>Recomendaciones</p>,
  // },
  // {
  //   field: "Disponible",
  //   headerName: "Disponible",
  //   width: 250,
  //   headerAlign: "center",
  //   renderHeader: () => <p style={design.headerTable}>Disponible</p>,
  // },
  {
    field: "Precio",
    headerName: "Precio",
    width: 250,
    headerAlign: "center",
    renderHeader: () => <p style={design.headerTable}>Precio</p>,
    valueFormatter: ({ row }) => {
      return `${row.Precio.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
        currencyDisplay: "symbol",
      })}`;
    },
  },
  // {
  //   field: "responsable",
  //   headerName: "responsable",
  //   width: 250,
  //   headerAlign: "center",
  //   renderHeader: () => <p style={design.headerTable}>Responsable</p>,
  // },
  // {
  //   field: "Imagen",
  //   headerName: "Imagen",
  //   width: 250,
  //   headerAlign: "center",
  //   renderHeader: () => <p style={design.headerTable}>Imagen</p>,
  // },
];
