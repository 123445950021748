/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, CircularProgress } from "@mui/material";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebasev2";
import { useAuth } from "../Context/Auth_v2/Auth.context";
import { validateToken } from "../Apis/validateToken";
import CardInfoPed from "../Components/CardInfoPed";
import WaitServices from "./Wait";
import { useDimensions } from "../hooks/useDimensions";
import { castBranche } from "../Utils/castBranche";
import { castStore } from "../Utils/castStore";

/**
 * Componente que muestra el dashboard del usuario.
 * @returns {JSX.Element} Elemento JSX que representa el componente Dashboard.
 */
const Dashboard = () => {
  // Estado para almacenar los pedidos
  const [pedidos, setPedididos] = useState([]);
  // Estado para verificar si está cargando
  const [loading, setLoading] = useState(true);
  // Estado para almacenar la franquicia
  const [store, setStore] = useState(true);
  // Estado para almacenar la sucursal
  const [branch, setBranch] = useState(true);
  // Variable para almacenar la franquicia
  let franquicia = null;
  // Dimensiones de la ventana
  const dimensionWidth = useDimensions("width");
  const Tam = dimensionWidth < 1000;
  // Contexto de autenticación
  const { token, user } = useAuth();

  // Función para obtener los pedidos al cargar el componente o al cambiar el token o usuario
  useEffect(() => {
    getPedidos();
  }, [user, token]);

  // Función para obtener los pedidos desde Firebase
  const getPedidos = async () => {
    // Si no hay token o usuario, no hace nada
    if (!token || !user.uid) return null;

    // Validar el token
    const response = await validateToken(token, user.uid);
    // Si la respuesta no es un objeto, muestra el error
    if (typeof response !== "object") {
      console.log(response);
      return;
    }

    // Almacenar la franquicia y la sucursal
    franquicia = response.store;
    setBranch(response.branch);
    setStore(response.store);

    setLoading(true);
    // Consultas para cada tipo de pedido
    const laboratorioQuery = queryFunction("laboratorio");
    const radiologiaQuery = queryFunction("radiologia");
    const operadorQuery = queryFunction("operador");

    // Construir los datos de los pedidos
    const dataLab = await constructData(laboratorioQuery);
    const dataRad = await constructData(radiologiaQuery);
    const dataOpe = await constructData(operadorQuery);
    let fixPedidos = [...dataLab, ...dataRad, ...dataOpe];

    // Filtrar pedidos que no estén completos
    const pedidos = fixPedidos.filter(
      (e) =>
        e.status.radiologo < 110 ||
        e.status.administrador < 110 ||
        e.status.laboratorista < 110 ||
        e.status.operador < 110
    );

    // Filtrar pedidos duplicados
    const orders = pedidos.filter((obj, index) => {
      return index === pedidos.findIndex((o) => obj.np === o.np);
    });

    // Ordenar pedidos por número de pedido
    setPedididos(orders.sort((a, b) => a.np - b.np));
  };

  // Función para construir una consulta de Firebase
  const queryFunction = (data) => {
    // Verificar si data es undefined
    if (data === undefined) {
      return null;
    }
    const ref = collection(db, "PedidosAux");
    const initialDay = new Date();
    const fechaSinMinutos = new Date(
      initialDay.getFullYear(),
      initialDay.getMonth(),
      initialDay.getDate(),
      0,
      0,
      0
    );
    const endDate = new Date(
      initialDay.getFullYear(),
      initialDay.getMonth(),
      initialDay.getDate(),
      23,
      59,
      59
    );

    return query(
      ref,
      where("services_Dates." + data, ">=", fechaSinMinutos),
      where("services_Dates." + data, "<=", endDate),
      where("franquicia", "==", franquicia)
    );
  };

  // Función para construir datos a partir de la consulta de Firebase
  const constructData = async (query) => {
    const array = await getDocs(query);
    setLoading(false);
    const order = array.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
        ref: doc.ref,
      };
    });
    return Promise.all(order);
  };

  // Renderizado condicional
  if (loading && !pedidos.length) {
    return (
      <>
        <Stack style={design.Align} mt={25}>
          <CircularProgress />
        </Stack>
      </>
    );
  }

  return (
    <>
      <Grid container alignItems="center" mb={5}>
        {/* Información del usuario */}
        <Grid item xs={12} md={12} xl={12}>
          <Stack style={design.Align} spacing={0}>
            <h1 style={design.title}>Bienvenido &nbsp;</h1>
            <Box
              width={Tam ? "40%" : "15%"}
              height={Tam ? "20%" : "15%"}
              component="img"
              src="https://movicaremx.com/IMG/MoviLabs/movilabs_300.png"
              alt={"Franquicia_name"}
            />
          </Stack>
          <h2 style={design.subtitle}>
            {user?.displayName} <br />{" "}
            {`${castStore()[store]} ${castBranche()[branch]}`}
          </h2>
        </Grid>
      </Grid>

      <Grid container direction={Tam ? "column" : "row"} style={design.cards}>
        {/* Renderizar componentes dependiendo de los pedidos */}
        {!pedidos.length ? (
          <WaitServices />
        ) : (
          pedidos.map((doc, idx) => (
            <CardInfoPed loading={false} data={doc} index={idx} />
          ))
        )}
      </Grid>
    </>
  );
};

export default Dashboard;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subtitle: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    letterSpacing: "0em",
    textAlign: "center",
    marginTop: "-5%",
    marginRight: "5%",
  },
  cards: {
    maxWidth: "100%",
    overflow: "auto",
    marginLeft: "1.7%",
    justifyContent: "center",
    alignItems: "center",
  },
  Align: {
    direction: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};
