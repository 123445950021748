import React, { useRef, useEffect, useState } from "react";
import algoliasearch from "algoliasearch";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomTextField from "./CustomTextField";
import { validateToken } from "../Apis/validateToken";
import { useAuth } from "../Context/Auth_v2/Auth.context";
import { getClient } from "../Controllers/getClient.controller";
import { useOrder } from "../Context/Ordenes_v2/Order.context";
import { useQuote } from "../Context/Quote/Quote.context";

// Inicializa el cliente de Algolia con las credenciales proporcionadas
const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);

// Componente de búsqueda de usuarios
const SearchUser = ({ onSelect, onChange, isQuote, Tam }) => {
  const quoteContext = useQuote();
  const orderContext = useOrder();

  // Definir `setPatient` condicionalmente, pero después de llamar los hooks
  const { setPatient, selectUser } = isQuote ? quoteContext : orderContext;
  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();

  // Hook de autenticación para obtener token y usuario actual
  const { token, user } = useAuth();

  // Estado para almacenar la información de la sucursal y tienda
  const [{ store }, setData] = useState({ branch: "", store: "" });

  // Inicializa el índice de búsqueda de Algolia para la tienda actual
  let index = client.initIndex(`ML_US_${store}`);

  // Hook para almacenar la referencia del debounce
  const debounceRef = useRef();

  // Estado para almacenar los resultados de la búsqueda
  const [results, setResults] = useState([]);

  // Estado para almacenar el valor seleccionado del usuario
  const [selectedUserName, setSelectedUserName] = useState(null);

  // Efecto para obtener y establecer la información de la sucursal y tienda
  useEffect(() => {
    validateToken(token, user.uid).then(setData).catch(console.log); // Maneja errores de la obtención de datos de la sucursal y tienda
  }, [token, user.uid]);

  // Función de debounce para retrasar la búsqueda mientras se escribe
  const onQueryChange = (e) => {
    setSelectedUserName(e.target.value);
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => handleChange(e), 500);
  };

  // Función para realizar la búsqueda en Algolia
  const performSearch = async (value) => {
    try {
      const { hits } = await index.search(value, {
        hitsPerPage: 15,
      });

      // console.log("hits",hits);
      // Mapea los resultados y formatea los datos
      const results = hits.map((hit) => {
        const { objectID, ...rest } = hit;
        return { id: objectID, ...rest };
      });

      setResults(results);
      onChange(value);
    } catch (error) {
      // Maneja errores de búsqueda en Algolia
      handleError(error);
    }
  };

  // Maneja el cambio en el campo de búsqueda
  const handleChange = (e) => {
    const { value } = e.target;
    // Actualiza selectedUserName directamente aquí
    setSelectedUserName(value);
    // Si no hay valor, reinicia los resultados
    !value ? setResults([]) : performSearch(value);
  };

  // Maneja errores y muestra notificaciones de Snackbar
  const handleError = (error) => {
    enqueueSnackbar(error.message, {
      variant: "error",
      preventDuplicate: true,
      autoHideDuration: 1600,
    });
  };

  // Maneja la selección de un resultado de búsqueda
  const handleSelect = (response) => {
    setPatient(response);
    setResults([]);
    onSelect(response);

    setSelectedUserName(response?.DatosPersonales?.nombre); // Guarda el nombre del usuario seleccionado
  };
  // Renderiza el componente de búsqueda
  return (
    <>
      <CustomTextField
        searchPxRef={debounceRef}
        label="Nombre del paciente"
        name="name"
        grid={12}
        gridXs={12}
        InputLabelProps={{ shrink: true }}
        value={selectedUserName || ""}
        onChange={onQueryChange}
      />
      {results.length ? (
        <List
          dense={false}
          style={{
            width: Tam ? "80%" : "42%",
            margin: !Tam ? "3% 0%" : "17% 1%",
            ...design.listStyle,
          }}
        >
          {results.map(
            ({ nombre, correo, telefono, path, fraude, discount }) => (
              <ListItem
                sx={{
                  backgroundColor: fraude ? "#ffb8b8" : "transparent",
                }}
                secondaryAction={<p>{discount && discount + "%"}</p>}
              >
                <ListItemButton
                  key={path}
                  onClick={() => {
                    getClient(path).then(handleSelect).catch(handleError);
                    selectUser(path);
                  }}
                  disabled={fraude ? true : false}
                >
                  <ListItemText
                    sx={{ paddingLeft: 2 }}
                    primary={nombre}
                    secondary={correo + " - " + telefono}
                  />
                  <p>{fraude && "FRAUDE"}</p>
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      ) : null}
    </>
  );
};

export default SearchUser;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  listStyle: {
    backgroundColor: "#FFF",
    borderRadius: 5,
    boxShadow: "0px 4px 4px rgba(14, 91, 145, 0.25)",
    overflow: "auto",
    position: "absolute",
    zIndex: 4,
    maxHeight: "30%",
    fontFamily: "Lexend",
  },
};
