import { useEffect, useState } from "react";
import firebase from "../firebase";
import { USERS_DISPONIBILITY_WITH_MAX_HOURS } from "../Controllers/usersDisponibility";

const today = new Date();

/**
 * Represents a hook to manage hours and availability based on user data.
 *
 * @returns {Object} An object containing hours, loading state, selected date, and utility functions.
 * @property {Array<Object>} hours - An array of hours with their respective dates.
 * @property {boolean} loading - A flag indicating if the hours are currently being loaded.
 * @property {Function} setSelectedDate - A function to set the selected date.
 * @property {Date} selectedDate - The currently selected date.
 * @property {number} isToday - The timestamp of the selected date.
 */
today.setHours(0, 0, 0, 0);
export const useHours = () => {
  /**
   * State to manage the loading status.
   * @type {boolean}
   */

  const [loading, setLoading] = useState(false);

  /**
   * State to manage the hours.
   * @type {Array<Object>}
   */
  const [hours, setHours] = useState([]);

  /**
   * State to manage the selected date.
   * @type {Date}
   */
  const [selectedDate, setSelectedDate] = useState(null);

  /**
   * Current user ID from Firebase authentication.
   * @type {string|null}
   */
  const uid = firebase.auth?.currentUser?.uid;

  /**
   * Function to create the array of hours based on user availability.
   * @returns {Array<Object>} An array of hours with their respective dates.
   */
  const createHours = () => {
    setLoading(true);
    setTimeout(() => {
      // const MAX_HOURS =  15;
      const MAX_HOURS =
        (USERS_DISPONIBILITY_WITH_MAX_HOURS.find((e) => e.uid === uid) || {}).maxHours || 19;

      const hours = [];

      for (let index = 7; index < MAX_HOURS; index++) {
        const PRE_MUTATION = new Date(selectedDate);
        PRE_MUTATION.setHours(index, 0, 0);
        hours.push({
          hour: index + ":00",
          minutes: "00",
          date: PRE_MUTATION,
          services: 0,
        });
      }
      setHours(hours);
      setLoading(false);
      return hours;
    }, 1000);
  };
  useEffect(() => {
    if (uid) createHours();
    // eslint-disable-next-line
  }, [selectedDate, uid]);

  return {
    hours,
    loading,
    setSelectedDate,
    selectedDate,
  };
};
