/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "../styles/menu.css";
import { useAuth } from "../Context/Auth_v2/Auth.context";
import CustomMenu from "./CustomMenu";
import { useDimensions } from "../hooks/useDimensions";
import { validateToken } from "../Apis/validateToken";

const handleNavbar = (pathname) => {
  const [_, key] = pathname.split("/");
  switch (key) {
    case "create-order":
      return false;
    case "order-details":
      return false;
    case "receiver-page":
      return false;
    case "create-quote":
      return false;
    default:
      return true;
  }
};

const Menu = () => {
  const dimensionHeight = useDimensions("height");
  const dimensionWidth = useDimensions("width");
  const { pathname } = useLocation();
  const { token, logOut, user } = useAuth();

  const menuItems = [
    {
      title: "Órdenes",
      links: [
        { id: 0, to: "/create-order", label: "Crear orden" },
        { id: 1, to: "/view-orders", label: "Ver órdenes" },
        { id: 2, to: "/archive", label: "Archivo" },
      ],
    },
    {
      title: "Catálogo",
      links: [
        { id: 3, to: "/add-study", label: "Dar de alta estudio" },
        { id: 4, to: "/view-catalog", label: "Ver catálogo" },
      ],
    },
    {
      title: "Cotizaciones",
      links: [
        { id: 5, to: "/create-quote", label: "Crear cotización" },
        { id: 6, to: "/view-quotes", label: "Ver cotizaciones" },
      ],
    },
    {
      title: "Zonas de servicio",
      links: [{ id: 7, to: "/service-areas", label: "Ver zonas de servicio" }],
    },
    {
      title: "Personal",
      links: [
        { id: 8, to: "/Users", label: "Ver personal" },
      ],
    },
    {
      title: "Estadísticas",
      links: [
        { id: 9, to: "/podium", label: "Estadísticas" },
      ],
    },
  ];
  const [selected, setSelected] = useState();
  const closeSesion = async () => {
    const response = await validateToken("", user.uid);
    // console.log('response',response);
    if (user.uid && response) {
      await logOut(user?.uid, response.store);
    }
  };
  if (!token) return null;

  return (
    <>
      <div
        className="topnav"
        style={{
          borderBottomRightRadius: handleNavbar(pathname) ? "20px" : null,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {dimensionWidth < 1000 && <CustomMenu menuItems={menuItems} />}
          <Link to="/">
            <Box
              width={dimensionWidth < 1000 ? "30%" : "10%"}
              height={dimensionWidth < 1000 ? "20%" : "20%"}
              sx={{
                marginTop: dimensionWidth < 1000 ? 0 : 0,
                marginLeft: dimensionWidth < 1000 ? "2%" : "-75%",
              }}
              component="img"
              src="https://movicaremx.com/IMG/MoviLabs/movilabs%20color.png"
              alt="Logotipo-MoviLabs"
            />
          </Link>
          {token && (
            <div
              style={{
                marginRight: dimensionWidth < 1000 ? "5%" : "10%",
              }}
            >
              <Box
                width={24}
                height={24}
                component="img"
                src="https://movicaremx.com/IMG/MoviLabs/door_front.png"
                alt="Logotipo-MoviLabs"
                onClick={closeSesion}
              />
            </div>
          )}
        </Stack>
      </div>

      {dimensionWidth < 1000 ||
        (handleNavbar(pathname) && (
          <div
            className="menu"
            style={{
              height:
                dimensionWidth < 1500
                  ? dimensionHeight - 95
                  : dimensionHeight - 105,
            }}
          >
            {/* ...  elementos del menú ... */}
            <div style={design.Scroll}>
              {menuItems.map((menuItem, index) => (
                <div key={index}>
                  <h1 style={design.title}>{menuItem.title}</h1>
                  {menuItem.links.map((link) => (
                    <>
                      <div
                        style={{
                          backgroundColor: selected === link.id && "#ABD7FF",
                          width: "80%",
                          marginLeft: "20%",
                          borderTopLeftRadius: 20,
                          borderBottomLeftRadius: 20,
                        }}
                      >
                        <Link
                          key={link.id}
                          to={link.to}
                          style={design.subtitle}
                          onClick={() => {
                            setSelected(link.id);
                          }}
                        >
                          {link.label}
                        </Link>
                        <br />
                      </div>
                    </>
                  ))}
                </div>
              ))}
            </div>
            <p style={design.caption}>
              {process.env.REACT_APP_VERSION}v <br />
              MoviCare Servicios Integrales S.A.P.I. de C.V Copyright ® Todos
              los derechos reservados.
            </p>
          </div>
        ))}
    </>
  );
};

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "25px",
    letterSpacing: "0em",
    textDecoration: "none",
    marginLeft: "13%",
    marginRight: "5%",
    fontSize: 24,
  },
  subtitle: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "30px",
    textDecoration: "none",
    letterSpacing: "0em",
    textAlign: "left",
    marginLeft: "5%",
  },
  caption: {
    fontFamily: "Lexend",
    fontSize: 11,
    fontWeight: 500,
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "rgba(9, 48, 70, 0.25)",
    marginTop: "20%",
  },
  Scroll: {
    height: "80%",
    overflow: "auto",
  },
};

export default Menu;
