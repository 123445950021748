import axios from "axios";

const URL_PRODUCTION = process.env.REACT_APP_URL_SERVER;
const URL_DEV = process.env.REACT_APP_URL_SERVERLOCAL;
/**
 * Instancia de Axios para interactuar con la API del servidor.
 * @type {import("axios").AxiosInstance}
 * @throws {Error} - Error si la instancia de Axios no puede ser creada.
 */
export const servidor = axios.create({
  /**
   * URL base de la API, dependiendo del entorno (producción o desarrollo).
   * @type {string}
   */
  baseURL:
    window.location.hostname === "localhost" ||
    /^192\.168\.\d{1,3}\.\d{1,3}$/.test(window.location.hostname)
      ? URL_DEV
      : URL_PRODUCTION,
  /**
   * Tiempo máximo de espera para las solicitudes en milisegundos.
   * @type {number}
   */
  timeout: 60000,
});
