import React, { useState } from "react";
import { Grid } from "@mui/material";
import CostomTableCrear from "../CostumTableCrear";
import { DarkGreenButton } from "../../../../CSS/Contained/DarkGreenButton";

const TicketModify = ({ windowHeight, widthTelefono }) => {
  //----------------- Variables useOrden()
  const [loading, setLoading] = useState(false);
  const [shoppingCart, setShoppingCart] = useState([]);
  const [form, setForm] = useState({});

  const onProductCountChange = () => {
    console.log("TIcket!");
  };
  return (
    <>
      <Grid style={design.ticket}>
        <p style={design.title}>
          <b>Nombre: </b>
          {form?.name}
        </p>
        <p style={design.title}>
          <b>Correo electrónico: </b>
          {form?.email && form?.email}
        </p>
        <p style={design.title}>
          <b>Teléfono: </b>
          {form?.phone && form?.phone}
        </p>
        <p style={design.title}>
          <b>Dirección: </b>
          {form["direccion.calle"] && `${form["direccion.calle"]},`}
          {form["direccion.numeroExt"] && `${form["direccion.numeroExt"]},`}
          {form["direccion.numeroInt"] && `${form["direccion.numeroInt"]},`}
          {form["direccion.cp"] && `${form["direccion.cp"]},`}
          {form["direccion.ciudad"] && `${form["direccion.ciudad"]},`}
          {form["direccion.estado"] && `${form["direccion.estado"]}.`}
        </p>
        <p style={design.title}>
          <b>Referencias: </b>
          {form["direccion.referencia"] && `${form["direccion.referencia"]}.`}
        </p>
        <CostomTableCrear
          botomDisable={false}
          order={shoppingCart}
          onProductCountChange={onProductCountChange}
          //   Se modifico totales por form.totales
          totales={form.totales}
          //   store={store}
          //   branch={branch}
          payment={form.payment}
          height={340}
        />
        <DarkGreenButton
          disabled={loading}
          onClick={() => {
            // createOrder();
            console.log("Modificar orden");
          }}
          width={"90%"}
        >
          Agendar
        </DarkGreenButton>
      </Grid>
    </>
  );
};
export default TicketModify;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  ticket: {
    padding: 20,
  },
  title: {
    textAlign: "left",
    color: "#093046",
    fontSize: 17,
  },
};
