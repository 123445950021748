/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import StripedGrid from "../Components/StripeDataGrid";
import getZonasQuery from "../Apis/getZonesQuery";
import { getBranches } from "../Apis/getSucursales";
import { useAuth } from "../Context/Auth_v2/Auth.context";

/**
 * Componente que muestra las áreas de servicio disponibles y un mapa interactivo.
 * @returns {JSX.Element} Elemento JSX que representa el componente ServiceAreas.
 */
const ServiceAreas = () => {
  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();

  // Estado para almacenar los datos de las zonas de servicio
  const [dataZones, setDataZones] = useState([]);

  /**
   * Función asíncrona para obtener las zonas de servicio.
   * @returns {Promise<void>} Promesa vacía.
   */
  const getZonas = async () => {
    try {
      const token = localStorage.getItem("token");

      // Obtener las zonas de servicio desde la API
      const zonasResponse = await getZonasQuery(token);

      // Asignar un id único a cada objeto en zonasResponse
      const dataWithIds = zonasResponse.map((row, index) => ({
        ...row,
        id: index, // Utilizar el índice como id
      }));

      // Actualizar el estado con las zonas de servicio
      setDataZones(dataWithIds);
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar(error.message, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
    }
  };

  const { user, token } = useAuth();
  const [loading, setLoading] = useState(true); // Estado de carga
  const [sucursales, setSucursales] = useState([]); // Estado para almacenar las sucursales

  useEffect(() => {
    if (user?.uid && token) {
      setLoading(true); // Inicia la carga
      getBranches(user?.uid, token)
        .then((response) => {
          console.log("Sucursales:", response); // Verifica cómo llegan los datos
          setSucursales(response);
          setLoading(false); // Finaliza la carga después de recibir los datos
        })
        .catch(() => {
          setLoading(false); // En caso de error también finaliza la carga
        });
    }
  }, [token, user]);

  // Estado para almacenar las columnas que se van a mostrar
  const [columns, setColumns] = useState(baseColumns);
  useEffect(() => {
    // Verifica si hay al menos una fila con el campo 'Cobertura'
    const hasCoverageField = dataZones.some(
      (row) => row.Cobertura !== undefined && row.Cobertura !== null
    );

    // Construye las columnas según la existencia del campo 'Cobertura'
    const dynamicColumns = [...baseColumns];

    if (hasCoverageField) {
      dynamicColumns.push({
        field: "Cobertura",
        headerName: "Cobertura",
        width: 200,
        headerstyle: design.headerTable,
      });
    }

    setColumns(dynamicColumns);
  }, [dataZones]);
  // Obtener las zonas de servicio al cargar el componente
  useEffect(() => {
    getZonas();
  }, []);

  /**
   * Maneja el clic en una fila de la tabla.
   * @param {string} cp Código postal de la fila seleccionada.
   */
  const handleRowClick = (cp) => {
    if (sucursales.length > 0 && sucursales[0]?.map) {
      const mapa = sucursales[0]?.map;
      const postalCode = cp; // Código postal seleccionado
      const finalUrl = `${mapa}&q=${postalCode}`;
      // console.log(finalUrl);
      window.open(finalUrl, "_blank");
    } else {
      console.log("No hay mapa disponible");
    }
  };

  return (
    <>
      <Stack flex={1} direction="column">
        {/* Título */}
        <h1 style={design.title}>Zonas disponibles</h1>
        <Grid container>
          {/* Tabla de zonas de servicio */}
          <Grid xs={12} md={6} xl={6}>
            <StripedGrid
              loading={false}
              columns={columns}
              rows={dataZones.map((row) => ({ ...row, id: row.id }))}
              pageSize={9}
              oddBackgroundColor="#d5ddfa"
              evenBackgroundColor="#becaf8"
              oddOpacity={0.7}
              w="95%"
              onRowClick={(row) => handleRowClick(row.row.CP)}
            />
          </Grid>
          {/* Mapa interactivo */}
          <Grid xs={12} md={6} xl={6}>
            {loading ? (
              <p style={design.title}>Cargando mapa...</p>
            ) : sucursales.length > 0 && sucursales[0]?.map ? (
              <iframe
                title="Zonas disponibles"
                src={sucursales[0].map}
                style={design.mapa}
              />
            ) : (
              <p style={design.title}>No hay mapa disponible</p> // Mensaje si no hay mapa después de cargar
            )}
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default ServiceAreas;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  mapa: {
    marginLeft: "3%",
    width: "95%",
    height: "400px",
    marginTop: "3%",
  },
};

// Columnas de la tabla de zonas de servicio
const baseColumns = [
  {
    field: "CP",
    headerName: "Código postal",
    width: 180,
    headerstyle: design.headerTable,
  },
  {
    field: "Colonia",
    headerName: "Colonia",
    width: 200,
    headerstyle: design.headerTable,
  },
  {
    field: "Delegacion",
    headerName: "Delegación",
    width: 200,
    headerstyle: design.headerTable,
  },
  {
    field: "Sucursales",
    headerName: "Sucursal",
    width: 200,
    headerstyle: design.headerTable,
  },
];
