import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import CustomTextField from "../CustomTextField";
import { useQuote } from "../../Context/Quote/Quote.context";
import SearchUser from "../SearchUser";
import { AnimatedBox } from "../../CSS/AnimatedBox";
import Modal from "../Modal";

const CustomData = ({widthTelefono}) => {
  const {
    form,
    setForm,
    handleCustomForm,
    patient,
    imagePromotion,
    imageDiscounts,
    imageDiscountsPublic,
  } = useQuote();

  const handleChangeForm = (e) => {
    const key = e.target?.name;
    const value = e.target?.value;
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleSelect = ({ DatosPersonales, path, refUsuario }) => {
    const { nombre, correo, telefono } = DatosPersonales;

    handleCustomForm("phone", telefono ?? "");
    handleCustomForm("email", correo ?? "");
    handleCustomForm("name", nombre ?? "");
    handleCustomForm("refUsuario", refUsuario ?? "");
    handleCustomForm("clientPath", path ?? "");
  };

  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <div id="SECTION-0">
        <p style={design.title}> 1. Datos de contacto</p>
        <Grid container style={design.Card}>
          <SearchUser
            onSelect={handleSelect}
            onChange={(value) => handleCustomForm("name", value)}
            isQuote={true}
          />
          <CustomTextField
            label="Teléfono"
            type="phone"
            value={form.phone}
            grid={6}
            gridXs={12}
            required={true}
            name="phone"
            onChange={handleChangeForm}
            maxLength={10}
            InputLabelProps={{ shrink: true }}
          />
          <CustomTextField
            label="Correo electrónico"
            type="email"
            value={form.email}
            width="96%"
            grid={6}
            gridXs={12}
            required={false}
            name="email"
            onChange={handleChangeForm}
            InputLabelProps={{ shrink: true }}
          />
          <Stack
            direction={!widthTelefono ? "row" : "column"}
            ml={widthTelefono ? 7 : 3}
            spacing={widthTelefono ? 1 : 9}
            alignItems="center"
            justifyContent="space-around"
          >
            {info && (
              <AnimatedBox
                component="img"
                alt="Descuentos"
                src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Descuento.png"
                m="0% 2%"
                // 500 x 500
                width={50}
                height={50}
                onClick={handleClick}
              />
            )}
            <p style={design.info}>
              Número de servicios: {patient?.numberOfServices}
            </p>
            <p style={design.info}>
              Fecha del primer servicio: {patient?.firstService}
            </p>
            <p style={design.info}>
              Fecha del último servicio: {patient?.lastService}
            </p>
          </Stack>
        </Grid>
        <Modal.Discounts
          open={open}
          handleClose={handleClick}
          setInfo={setInfo}
          promotionOfTheMonth={imagePromotion}
          imageDiscounts={imageDiscounts}
          imageDiscountsPublic={imageDiscountsPublic}
        />
      </div>
    </>
  );
};

export default CustomData;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 23,
    lineHeight: "20px",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
  info: {
    textAlign: "center",
    color: "#4b818b",
    fontWeight: "600",
  },
};
