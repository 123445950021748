import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useDimensions } from "./hooks/useDimensions";
import { useScreenSize } from "./hooks/useScreenSize";
import ScrollToTop from "./hooks/useScrollToTop";
import AuthState from "./Context/Auth_v2/Auth.context";
import OrderState from "./Context/Ordenes_v2/Order.context";
import Menu from "./Components/Menu";
import ImportCSV from "./Components/Catalogo/ImportCSV";
import ProtectRoutes from "./Views/ProtectRoutes";
import ComponentsStyle from "./Views/ComponentsStyle";
import Dashboard from "./Views/Dashboard";
import CreateOrder from "./Views/Ordenes/CreateOrder";
import OrderDetails from "./Views/Ordenes/OrderDetails";
import ViewOrders from "./Views/Ordenes/ViewOrders";
import Archive from "./Views/Ordenes/Archive";
import Users from "./Views/Users/Users";
import ViewCatalog from "./Views/Catalogo/ViewCatalog";
import ServiceAreas from "./Views/ServiceAreas";
import Crear from "./Views/Catalogo/Crear";
import ReceiverPage from "./Views/SegundaPantalla/ReceiverPage";
import Login from "./Views/Login";
import Error404 from "./Views/404";
import Modify from "./Views/Ordenes/Modify";
import CreateQuote from "./Views/Cotizacion/CreateQuote";
import ViewQuote from "./Views/Cotizacion/ViewQuote";
import QuoteState from "./Context/Quote/Quote.context";
import Podiums from "./Components/Podiums";

const App = () => {
  /**
   * Hook que devuelve la dimensión actual de la ventana en la dirección especificada.
   *
   * @function useDimensions
   * @param {("height"|"width")} dimension - Especifica la dimensión a obtener, ya sea "height" (altura) o "width" (ancho).
   *
   * @returns {number} La dimensión actual de la ventana (en píxeles) para la dirección especificada.
   */
  const dimensionHeight = useDimensions("height");
  /**
   * Obtiene la ruta actual de la URL.
   * @constant {string}
   */
  const rutaActual = window.location.pathname;

  /**
   * Hook que proporciona el tamaño de pantalla actual y devuelve
   * indicadores booleanos para diferentes tamaños de dispositivos.
   *
   * @typedef {Object} ScreenSize
   * @property {boolean} isMobile - Indica si el tamaño de pantalla corresponde a un dispositivo móvil.
   * @property {boolean} isTabletVertical - Indica si el tamaño de pantalla corresponde a una tableta en orientación vertical.
   * @property {boolean} isTabletHorizontal - Indica si el tamaño de pantalla corresponde a una tableta en orientación horizontal.
   * @returns {ScreenSize} Un objeto que contiene los valores booleanos para isMobile, isTabletVertical, e isTabletHorizontal.
   */

  const { isMobile, isTabletVertical, isTabletHorizontal } = useScreenSize();

  /**
   * Desplaza la ventana hasta la parte superior cuando el componente se monta.
   * El desplazamiento se realiza de manera suave ("smooth").
   *
   * @function
   * @memberof useEffect
   * @description Esta función solo se ejecuta una vez al montar el componente,
   * ya que el array de dependencias está vacío.
   */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Suspense
      fallback={
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={210}
          height={118}
        />
      }
    >
      <Router>
        <AuthState>
          <ScrollToTop />
          <Menu />
          <div
            style={
              isMobile || isTabletVertical
                ? null
                : {
                    height:
                      rutaActual === "/login"
                        ? null
                        : isMobile || isTabletVertical || isTabletHorizontal
                        ? dimensionHeight - 80
                        : dimensionHeight - 90,
                    overflow: "auto",
                  }
            }
          >
            <Routes>
              <Route path="/componentsStyle" element={<ComponentsStyle />} />
              <Route path="/ImportCSVProducts" element={<ImportCSV />} />
              <Route
                path="/login"
                element={<Login isMobile isTabletVertical isTabletHorizontal />}
              />
              <Route path="/404" element={<Error404 />} />

              <Route element={<ProtectRoutes />}>
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/create-order"
                  element={
                    <OrderState>
                      <CreateOrder />
                    </OrderState>
                  }
                />
                <Route path="/order-details/id/Modify" element={<Modify />} />

                <Route path="/view-orders" element={<ViewOrders />} />
                <Route path="/archive" element={<Archive />} />
                <Route path="/add-study" element={<Crear />} />
                <Route path="/view-catalog" element={<ViewCatalog />} />

                <Route
                  path="/create-quote"
                  element={
                    <QuoteState>
                      <CreateQuote />
                    </QuoteState>
                  }
                />
                <Route path="/view-quotes" element={<ViewQuote />} />
                <Route path="/service-areas" element={<ServiceAreas />} />
                <Route path="/profile" element={<h1>Profile</h1>} />
                <Route path="/Users" element={<Users />} />
                <Route path="/order-details/:id" element={<OrderDetails />} />
                <Route
                  path="/receiver-page"
                  element={
                    <OrderState>
                      <ReceiverPage />
                    </OrderState>
                  }
                />
                <Route path="/podium" element={<Podiums />} />
              </Route>
            </Routes>
          </div>
        </AuthState>
      </Router>
    </Suspense>
  );
};

export default App;
