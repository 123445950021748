/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Info } from "@mui/icons-material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebasev2";
import timeStamp_toDateComplete from "../../Utils/timeStamp_toDateComplete";
import Personal from "../Ordenes/Personal";
import firebase from "../../firebase/firebase";
const History = ({ articulo, infoFranquicia }) => {
  const [history, setHistory] = useState({});
  const [Movements, setMovements] = useState([]);


  const get_Responsable = (responsable) => {
    const refResponsable = doc(db, "Franquicias", infoFranquicia.branch, "Personal", responsable);
    return refResponsable;
  };

  const getHistory = async () => {
    const path = `${articulo.path}/History/Movements`;
    const reference = doc(db, path);
    const response = await getDoc(reference);
    const uid = firebase.auth.currentUser.uid;

    if (!response.data()) {
      const newData = {
        Movimientos: [],
        descripcion: "Creación",
        fechaAlta: new Date(),
        origen: "MoviLabs",
        responsable: await get_Responsable(uid),
      };

      await setDoc(reference, newData);
      return;
    } else {
    
      if (response.data()) {
        const {
          Movimientos = [],
          responsable,
          description,
          fechaAlta,
        } = response.data();

        const responsableGet = await getDoc(responsable);
        const data = responsableGet.data();

        setHistory({
          responsable: data?.DatosPersonales?.nombre,
          description,
          fechaAlta,
        });
  
        setMovements(Movimientos);
      }
      return
    }
  };

  useEffect(() => {
    if (articulo.path) {
      getHistory();
    }
  }, [articulo, infoFranquicia]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer style={design.Table}>
      <Table aria-label="collapsible table">
        <TableHead style={design.Head}>
          <TableRow>
            <TableCell style={design.txt}>Fecha</TableCell>
            <TableCell style={design.txt}>Descripcion</TableCell>
            <TableCell style={design.txt}>Responsable</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableCell>{timeStamp_toDateComplete(history?.fechaAlta)}</TableCell>
          <TableCell>{history?.description}</TableCell>
          <TableCell>
            <Personal
              responsables={{
                responsable: history?.responsable || null,
              }}
            />
          </TableCell>
          {Movements.map((movement) => {
            return (
              <>
                <Rows
                  fecha={movement.fecha}
                  resp={movement?.responsable}
                  desc={movement?.description}
                  articulo={articulo} // Pasar los datos de la modificación como "art"
                  movements={{ ...movement }}
                />
              </>
            );
          })}
          <TableRow>
            <TableCell colSpan={6} align="right">
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={Movements.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Rows = ({ fecha, resp, desc, articulo, movements = {} }) => {
  const [details, setDetails] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>{timeStamp_toDateComplete(fecha)}</TableCell>
        <TableCell>{desc}</TableCell>
        <TableCell>
          <Grid container>
            <Grid xs={10} md={10} xl={10} lg={10}>
              <Personal
                responsables={{
                  responsable: resp || null,
                }}
              />
            </Grid>
            <Grid xs={2} md={2} xl={2} lg={2}>
              {movements && (
                <IconButton size="small" onClick={() => setDetails(!details)}>
                  <Info />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={details} timeout="auto" unmountOnExit>
            {/* Contenido de las modificaciones */}
            {/* Aqui es donde se muestra la informacion si existe alguna modificacion */}

            <Grid container style={design.Ajust}>
              <p style={design.title}>
                {`El articulo ${articulo["name"]} se ha modificado: `} <br />
                <br />
                {`${
                  articulo["name"] !== movements.detailsModify["name"]
                    ? `El nombre anteriormente era ${movements.detailsModify["name"]}.`
                    : ""
                }`}{" "}
                {`${
                  articulo["image"] !== movements.detailsModify["image"]
                    ? `Anteriormente la URL de imagen era: ${movements.detailsModify["image"]}.`
                    : ""
                }`}{" "}
                {`${
                  articulo["available"] !== movements.detailsModify["available"]
                    ? "La disponibilidad se modificó a No disponible."
                    : ""
                }`}{" "}
                {`${
                  articulo["departament"] !==
                  movements.detailsModify["departament"]
                    ? `El departamento se encontrabe en ${movements.detailsModify["departament"]}.`
                    : ""
                }`}{" "}
                {`${
                  articulo["subdepartament"] !==
                  movements.detailsModify["subdepartament"]
                    ? ` El subdepartamento anteriormente era ${movements.detailsModify["subdepartament"]}.`
                    : ""
                }`}
                {`${
                  articulo["cost"] !== movements.detailsModify["cost"]
                    ? `El costo era de ${movements.detailsModify["cost"]}.`
                    : ""
                }`}{" "}
                {`${
                  articulo["price"] !== movements.detailsModify["price"]
                    ? ` El precio era de ${movements.detailsModify["price"]}.`
                    : ""
                }`}{" "}
                {`${
                  articulo["deliveryTime"] !==
                  movements.detailsModify["deliveryTime"]
                    ? `El tiempo de entrega era ${movements.detailsModify["deliveryTime"]}.`
                    : ""
                }`}{" "}
                {`${
                  articulo["description"] !==
                  movements.detailsModify["description"]
                    ? `La descripción anterior era ${movements.detailsModify["description"]}.`
                    : ""
                }`}{" "}
                {`${
                  articulo["req"] !== movements.detailsModify["req"]
                    ? ` Los requisitos eran ${movements.detailsModify["req"]}.`
                    : ""
                }`}{" "}
                {`${
                  articulo["recomd"] !== movements.detailsModify["recomd"]
                    ? `Las recomendaciones eran ${movements.detailsModify["recomd"]}.`
                    : ""
                }`}
              </p>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  Table: {
    border: "1px solid rgb(17, 122, 101)",
    borderRadius: 20,
    marginTop: 15,
  },
  Head: { backgroundColor: "rgb(17, 122, 101)" },
  txt: { color: "#FFF", fontWeight: 700, fontSize: 15 },
  title: {
    color: "#787878",
    fontFamily: "Lexend",
    fontWeight: 400,
    fontSize: 14,
  },
  form: {
    fontWeight: "100",
    fontSize: 14,
    marginLeft: "1%",
    marginTop: 20,
  },
  Ajust: {
    alignItems: "center",
    // marginTop: -0,
  },
};

export default History;
