import React from "react";
import Lottie from "react-lottie";
import WaitAnimation from "../animated/1712687862492-wait.json";
import { Grid } from "@mui/material";
import { useDimensions } from "../hooks/useDimensions";

/**
 * Componente que muestra un mensaje y una animación de espera.
 * @returns {JSX.Element} Elemento JSX que representa el componente WaitServices.
 */
const WaitServices = () => {
  // Dimensiones de la ventana para adaptar el tamaño de la animación.
  const dimensionWidth = useDimensions("width");

  return (
    <>
      {/* Contenedor principal */}
      <Grid container style={design.root}>
        {/* Título */}
        <Grid item xs={12} md={12} xl={12}>
          <h1 style={design.title}>Actualmente no se encuentran servicios agendados</h1>
          {/* Animación de espera */}
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: WaitAnimation,
            }}
            // Estilo dinámico para adaptar el tamaño de la animación según el ancho de la ventana.
            style={{ width: dimensionWidth < 1000 ? "100%" : "35%",marginTop:'-5%' }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WaitServices;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  root: {
    backgroundColor: "#FFF",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 35,
    color: "#2E4666",
  },
};
