/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CircularProgress, IconButton, Stack, Tooltip } from "@mui/material";
import { BorderColor, Loop, SaveAlt, Undo } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import StripedGrid from "../../Components/StripeDataGrid";
import Origin from "../../Components/Ordenes/Origin";
import Personal from "../../Components/Ordenes/Personal";
import { servidor } from "../../servidor";
import { validateToken } from "../../Apis/validateToken";
import { castStore } from "../../Utils/castStore";
import { castBranche } from "../../Utils/castBranche";

const ViewQuote = () => {
  /**
   * Hook para obtener la información de las cotizaciones (quotes) desde el servidor
   * cuando el usuario está autenticado.
   *
   * @returns {void}
   */
  const { user } = useAuth();
  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();
  /**
   * State to manage the loading status.
   * @type {boolean}
   */
  const [loading, setLoading] = useState(false);

  /**
   * State to manage the data quotes.
   * @type {boolean}
   */
  const [quotes, setQuotes] = useState([]);

  /**
   * Función asíncrona que obtiene las cotizaciones disponibles para el usuario
   * autenticado. Verifica el token de usuario y realiza la petición al servidor.
   *
   * @async
   * @function getViewQuote
   * @returns {Promise<void>} - La promesa que se resuelve cuando la operación de obtener cotizaciones finaliza.
   */
  const getViewQuote = async () => {
    setLoading(true); // Cambia el estado a "cargando" mientras se realiza la solicitud

    try {
      // Verificar si existe el ID del usuario autenticado
      if (user?.uid) {
        // Validar el token del usuario
        const response = await validateToken("", user.uid);

        // Si la tienda (store) está presente en la respuesta, realizar la solicitud
        if (response?.store) {
          const responseGet = await servidor.get("/movilabs/getViewQuotes/", {
            params: { store: response.store },
          });

          // Actualizar las cotizaciones en el estado con la respuesta obtenida
          setQuotes(responseGet.data.quotes || []);
          setLoading(false); // Cambiar el estado de carga a "falso" cuando la operación finaliza
          enqueueSnackbar("Se obtienen las cotizaciones realizadas", {
            variant: "success",
            autoHideDuration: 2600,
            preventDuplicate: true,
          });
        }
      } else {
        console.log("No store found in response");
        setLoading(false);
        enqueueSnackbar("No se encuentra una franquicia asociada", {
          variant: "warning",
          autoHideDuration: 2600,
          preventDuplicate: true,
        });
      }
    } catch (error) {
      // En caso de error, se detiene la carga y se muestra el error en la consola
      setLoading(false);
      console.log("ERROR_GETVIEWQUOTE", error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 2600,
        preventDuplicate: true,
      });
    }
  };

  /**
   * Hook de efecto que ejecuta la función `getViewQuote` cada vez que cambia el valor de `user`.
   *
   * @function useEffect
   * @param {Array} dependencies - Array de dependencias que activa el efecto cuando cambia.
   */
  useEffect(() => {
    if (user?.uid) {
      getViewQuote(); // Llamar a la función para obtener las cotizaciones cuando el usuario está autenticado
    }
  }, [user]); // Ejecutar el efecto cuando cambia el valor de "user"

  /**
   * State to manage the loading status.
   * @type {boolean}
   */
  const [loadingAction, setLoadingAction] = useState(null);

  /**
   * Columnas para la tabla de vacantes.
   * Cada objeto representa una columna de la tabla.
   * @type {Array<{ field: string, headerName: string,renderCell:Object,valueFormatter:Object }>}
   */
  const columns = [
    {
      field: "folio",
      headerName: "Folio",
      headerClassName: "super-app-theme--header",
      width: 120,
      renderHeader: () => <p style={design.headerTable}>Folio</p>,
      headerAlign: "center",
      renderCell: ({ row }) => {
        const { folio = 0 } = row;
        return "COT-" + folio;
      },
    },
    {
      field: "DatosContacto.nombre",
      headerName: "Nombre",
      width: 250,
      renderHeader: () => <p style={design.headerTable}>Nombre</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { nombre = "" } = row.DatosContacto;
        return nombre;
      },
    },
    {
      field: "DatosContacto.correo",
      headerName: "Correo",
      width: 230,
      renderHeader: () => <p style={design.headerTable}>Correo</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { correo = "" } = row.DatosContacto;
        return correo || "N/A" ;
      },
    },
    {
      field: "DatosContacto.telefono",
      headerName: "Telefono",
      width: 150,
      renderHeader: () => <p style={design.headerTable}>Teléfono</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const { telefono = "" } = row.DatosContacto;
        return telefono;
      },
    },
    {
      field: "origen",
      headerName: "Origen",
      width: 240,
      renderHeader: () => <p style={design.headerTable}>Origen</p>,
      headerAlign: "center",
      renderCell: ({ row }) => (
        // console.log("ROW ORIGEN",row)
        <Origin
          franquicia={row?.franquicia}
          sucursal={row?.sucursal}
          origen={row?.origen}
        />
      ),
      valueFormatter: ({ row }) => {
        return row.origin;
      },
    },
    {
      field: "estudios",
      headerName: "estudios",
      width: 150,
      renderHeader: () => <p style={design.headerTable}>N. Estud</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        const orden = row.Orden;
        let total = 0;
        if (orden) {
          const y = orden
            .map((e) => e.cantidad)
            .reduce((prev, current) => prev + current, 0);
          total = total + y;
        }
        return total;
      },
    },
    {
      field: "total",
      headerName: "total",
      width: 130,
      renderHeader: () => <p style={design.headerTable}>Total</p>,
      headerAlign: "center",
      valueFormatter: ({ row }) => {
        return row.total.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
          currencyDisplay: "symbol",
        });
      },
    },
    {
      field: "responsable",
      headerName: "responsable",
      width: 190,
      renderHeader: () => <p style={design.headerTable}>Responsable</p>,
      headerAlign: "center",
      renderCell: ({ row }) => (
        <>
          <div style={design.columnList}>
            <Personal
              status={row.status}
              responsables={{
                responsable: row.responsable || null,
              }}
            />
          </div>
        </>
      ),
      valueFormatter: ({ row }) => {
        if(row.responsible){
          return row.responsible.nombre 
        }
      },
    },
    {
      field: "acciones",
      headerName: "acciones",
      width: 240,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Acciones</p>,
      renderCell: ({ row }) => {
        return ICON.map((doc, index) => (
          <Tooltip key={index} arrow title={doc.name}>
            <IconButton
              onClick={async (e) => {
                e.stopPropagation(); // Evitar que el evento clic se propague a la fila o tabla
                e.preventDefault(); // Prevenir comportamientos inesperados

                if (typeof doc.rute === "function") {
                  setLoadingAction(index); // Activar el spinner solo en el botón actual

                  try {
                    await doc.rute(
                      row.id,
                      row.franquicia,
                      row.sucursal,
                      row.folio
                    );
                  } catch (error) {
                    console.error(
                      `Error en la acción del icono ${doc.name}:`,
                      error
                    );
                    setLoadingAction(null); // Desactivar el spinner después de la acción
                    enqueueSnackbar(
                      `Error en la acción del icono ${doc.name}:`,
                      {
                        variant: "warning",
                        autoHideDuration: 2600,
                        preventDuplicate: true,
                      }
                    );
                  }
                  setLoadingAction(null); // Desactivar el spinner después de la acción
                } else {
                  console.error(
                    `El icono ${doc.name} no tiene una función válida en rute`
                  );
                  setLoadingAction(null); // Desactivar el spinner después de la acción
                  enqueueSnackbar(
                    `El icono ${doc.name} no tiene una función válida en rute`,
                    {
                      variant: "error",
                      autoHideDuration: 2600,
                      preventDuplicate: true,
                    }
                  );
                }
              }}
              disabled={loadingAction !== null && loadingAction !== index} // Deshabilitar otros botones si hay una acción en progreso
            >
              {loadingAction === index ? (
                <CircularProgress size={20} />
              ) : (
                doc.icon // Mostrar el icono si no está cargando
              )}
            </IconButton>
          </Tooltip>
        ));
      },
    },
  ];

  /**
   * Representa un array de objetos con iconos y acciones para realizar en una cotización.
   * Cada objeto incluye un icono, un nombre descriptivo y una ruta que ejecuta la acción correspondiente.
   *
   * El botón de acción que se cliquea mostrará un `<CircularProgress />` mientras la acción esté en progreso,
   * y deshabilitará los demás botones.
   *
   * @param {string} id - El ID de la cotización.
   * @param {boolean} franquicia - Indica si la cotización pertenece a "MoviLabs" o "MoviCare".
   * @param {string} sucursal - La sucursal asociada a la cotización.
   * @param {string} folio - El folio de la cotización.
   */
  const ICON = [
    // {
    //   icon: <BorderColor style={{ color: "#893e5e" }} />,
    //   name: "Editar",
    //   rute: (id, franquicia) => {
    //     console.log(`Editar: id=${id}, franquicia=${franquicia}`);
    //   },
    // },
    // {
    //   icon: <Loop style={{ color: "#104976" }} />,
    //   name: "Convertir",
    //   rute: () => console.log("Convertir"),
    // },
    {
      icon: <Undo style={{ color: "#0A493C" }} />,
      name: "Reenviar",
      rute: (id, franquicia) => mailForwarding(id, franquicia),
    },
    {
      icon: <SaveAlt style={{ color: "#0A493C" }} />,
      name: "Descargar",
      rute: (id, franquicia, sucursal, folio) =>
        downloadPDF(id, franquicia, sucursal, folio),
    },
  ];
  /**
   * Descarga un archivo PDF de una cotización desde el servidor.
   *
   * Envía una solicitud al servidor para obtener el PDF correspondiente a la cotización
   * y lo descarga localmente en el dispositivo del usuario.
   *
   * @async
   * @function downloadPDF
   * @param {string} id - El ID de la cotización que se quiere descargar.
   * @param {boolean} franquicia - Indica si la cotización pertenece a "MoviLabs" o "Movicare".
   * @param {string} sucursal - La sucursal asociada a la cotización.
   * @param {string} folio - El folio de la cotización.
   * @returns {Promise<void>} - Retorna una promesa que se resuelve cuando la descarga se ha completado.
   */
  const downloadPDF = async (id, franquicia, sucursal, folio) => {
    try {
      // Mostrar el spinner de descarga
      setLoadingAction(true);

      // Enviar la solicitud al servidor para obtener el PDF
      const response = await servidor.post(
        "/api/cotizaciones/downloadPDFQuote",
        {
          id,
          type: franquicia ? "MoviLabs" : "Movicare", // Definir el tipo de cotización
        }
      );

      // Verificar si se recibió una respuesta válida
      if (!response.data) {
        throw new Error("No se recibió ningún dato en la respuesta");
      }

      // Convertir el contenido del PDF a una cadena Base64
      const pdfBase64 = response.data;

      // Crear un enlace de descarga para el PDF
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${pdfBase64}`;
      link.setAttribute(
        "download",
        `Cotización - ${castStore()[franquicia]} - ${
          castBranche()[sucursal]
        }_${folio}.pdf`
      );
      link.style.display = "none"; // Ocultar el enlace

      // Agregar el enlace al DOM y simular un clic para iniciar la descarga
      document.body.appendChild(link);
      link.click();

      // Eliminar el enlace del DOM después de la descarga
      document.body.removeChild(link);

      // Ocultar el spinner de descarga después de completar la acción
      setLoadingAction(false);

      console.log("PDF descargado correctamente");

      // Mostrar una notificación indicando el éxito de la descarga
      enqueueSnackbar(
        `Cotización - ${castStore()[franquicia]} - ${
          castBranche()[sucursal]
        }_${folio}.pdf se ha descargado correctamente`,
        {
          variant: "success",
          autoHideDuration: 2600,
          preventDuplicate: true,
        }
      );
    } catch (error) {
      // Manejo de errores durante la descarga
      console.error("Error al descargar el PDF:", error.message);

      // Ocultar el spinner de descarga si ocurre un error
      setLoadingAction(false);

      // Mostrar una notificación de error
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 2000,
        preventDuplicate: true,
      });
    }
  };

  /**
   * Realiza el reenvío de una cotización al servidor y maneja la respuesta del servidor.
   *
   * Envía una solicitud POST al servidor para reenviar una cotización, verificando
   * si la respuesta es exitosa y mostrando notificaciones en función del resultado.
   *
   * @async
   * @function mailForwarding
   * @param {string} id - El ID de la cotización a reenviar.
   * @param {boolean} franquicia - Indica si la cotización pertenece a "MoviLabs" o "MoviCare".
   * @returns {Promise<void>} - Retorna una promesa que se resuelve cuando el reenvío se ha completado.
   */
  const mailForwarding = async (id, franquicia) => {
    try {
      // Mostrar el spinner de descarga
      setLoadingAction(true);

      // Enviar la solicitud al servidor para reenviar la cotización
      const response = await servidor.post("/api/cotizaciones/newquotation", {
        id: id,
        type: franquicia ? "MoviLabs" : "MoviCare",
        isForwarding: true, // Indica que es un reenvío
      });

      // Verificar si la solicitud fue exitosa basándonos en el código de estado
      if (response.status === 200) {
        const responseData = response.data;

        // Manejar errores enviados por el servidor
        if (responseData.msg && responseData.msg.includes("error")) {
          console.error("Error en la respuesta:", responseData.msg);
          enqueueSnackbar(responseData.msg, {
            variant: "error",
            autoHideDuration: 1600,
            preventDuplicate: true,
          });
        } else {
          // Notificar éxito en la petición
          console.log("Petición exitosa:", responseData.message);
          enqueueSnackbar(responseData.message, {
            variant: "success",
            autoHideDuration: 2600,
            preventDuplicate: true,
          });
        }
      } else {
        // Manejar el caso en que el código de estado no sea 200
        console.error("La solicitud falló con el estado:", response.status);
        enqueueSnackbar(response.status, {
          variant: "warning",
          autoHideDuration: 1800,
          preventDuplicate: true,
        });
      }

      // Ocultar el spinner de descarga después de completar la acción
      setLoadingAction(false);
    } catch (error) {
      // Capturar cualquier error ocurrido durante la solicitud
      console.error(
        "Ocurrió un error durante la solicitud newquotation:",
        error.message
      );

      // Ocultar el spinner de descarga después de completar la acción
      setLoadingAction(false);

      // Notificar el error
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 1800,
        preventDuplicate: true,
      });
    }
  };

  // Estado de carga mientras se realiza alguna acción o petición del historial
  if (loading) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt={25}
      >
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <>
      <Stack flex={1} direction="column">
        <h1 style={design.title}>Visualizar cotizaciones</h1>
        <StripedGrid
          loading={loading}
          columns={columns}
          rows={quotes}
          pageSize={25}
          evenBackgroundColor="#65B32E40"
          oddBackgroundColor="#E6E6E640"
          color="#00518c"
          bgColorHeader="#FFF"
          oddOpacity={0.7}
          filter={true}
          w="93%"
          name={"Archivo.csv"}
          rowHeight={80}
        />
      </Stack>
    </>
  );
};

export default ViewQuote;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  headerTable: {
    fontSize: 16,
    color: "#00518c",
    fontFamily: "Lexend",
    justifyContent: "center",
    textAlign: "center",
  },
};
