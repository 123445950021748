/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Módulo que gestiona la autenticación del usuario.
 * @module AuthState
 */
import React from "react";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import AuthReducer from "./reducer";
import { AUTH_TOKEN, LOGIN } from "./types";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth, db } from "../../firebase/firebasev2";
import { useSnackbar } from "notistack";
import { useLocation } from "react-router-dom";
import { validateToken } from "../../Apis/validateToken";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

/**
 * Contexto de autenticación.
 * @type {React.Context<AuthState>}
 */
// Creación del contexto de autenticación
export const AuthContext = createContext();
/**
 * Estado inicial del módulo de autenticación.
 * @typedef {Object} AuthState
 * @property {Object} user - Información del usuario autenticado.
 * @property {string} token - Token de autenticación.
 */
const initialState = { user: null, token: null };

// Lista de rutas que no requieren verificación de token.
const EXCEPTION_SCREENS = ["/login", "/receiver-page"];

/**
 * Componente funcional que proporciona un contexto de autenticación.
 * @function
 * @param {Object} props - Propiedades del componente.
 * @param {ReactNode} props.children - Componentes hijos a los que se proporcionará el contexto.
 * @returns {JSX.Element} El componente AuthState.
 */
// Se crea el componente funcional AuthState que utiliza el Hook useReducer para gestionar el estado de autenticación.
const AuthState = ({ children }) => {
  // Inicialización de variables
  const location = useLocation();
   /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [loading, setLoading] = useState(true);
  const [store, setStore] = useState("");
  const [dataStore, setDataStore] = useState("");
  const [dataUserAccess, setDataUserAccess] = useState();

  // Cambios en la autenticación del usuario y el estado de consecuencia
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      dispatch({
        type: LOGIN,
        payload: user,
      });
      setLoading(false);
      // Si hay un usuario autenticado, se actualiza el estado utilizando dispatch.
      // Se llama a la función login con el token almacenado en localStorage.
      const token = localStorage.getItem("token") || null;

      login(token);
    });
  }, []);

  // Maneja la validación del token cuando la ubicación cambia
  useEffect(() => {
    // Si la pantalla actual no está en la lista de excepciones (EXCEPTION_SCREENS),
    // se programa una verificación de token
    if (EXCEPTION_SCREENS.includes(location.pathname)) {
      return null;
    }
    // Si el state no esta lleno se devuelve null
    if (!state.user) {
      return null;
    }
    // Si la validación del token falla, se muestra un mensaje y se llama a la función logOut.
    setTimeout(async () => {
      try {
        const response = await validateToken("", state?.user?.uid);

        if (!response) {
          enqueueSnackbar("Sesión expirada", {
            variant: "success",
            preventDuplicate: true,
            autoHideDuration: 1600,
          });
        }
      } catch (error) {
        enqueueSnackbar("Sesión expirada", {
          variant: "warning",
          preventDuplicate: true,
          autoHideDuration: 1600,
        });
        logOut();
      }
    }, 1500);
  }, [location, state]);

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (state?.user?.uid) {
          const responseStore = await validateToken("", state?.user?.uid);
          setStore(responseStore.store);
          setDataStore(responseStore);
          const ref = doc(
            db,
            "Franquicias",
            responseStore.store,
            "Personal",
            state?.user?.uid
          );
          const response = await getDoc(ref);
          setDataUserAccess(response.data().rol);
        }
      } catch (error) {
        console.error("Error al obtener los datos del usuario:", error);
        return null;
      }
    };
    getUserData();
  }, [state?.token, state?.user?.uid]);

  // Actualiza el estado de autenticación
  const login = async (token = null) => {
    try {
      dispatch({
        type: AUTH_TOKEN,
        payload: token,
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "warning",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
      logOut();
    }
  };

  /**
   * Cierra la sesión del usuario y actualiza el historial de sesiones.
   * @async
   * @param {string} uid - ID del usuario.
   * @param {string} store - ID de la franquicia.
   * @returns {Promise<string>} - Mensaje de éxito al cerrar sesión.
   * @throws {Error} - Error si no se puede cerrar la sesión.
   */
  const logOut = async (uid, store) => {
    try {
      setLoading(true);

      if (!uid || !store) {
        throw new Error("Imposible cerrar sesión en este momento.");
      }

      // Referencia al usuario y a la sesión en Firestore
      const userRef = doc(db, "Franquicias", store, "Personal", uid);
      const sessionsRef = doc(userRef, "HistorialMovimientos", "Sesiones");

      // Verifica si existe un registro de sesión y crea uno nuevo si no existe
      const response = await getDoc(sessionsRef);
      if (!response.exists()) {
        await setDoc(sessionsRef, {
          Movimientos: [],
        });
      }

      // Obtiene la hora de inicio del usuario y el historial de movimientos de sesión
      const userResponse = await getDoc(userRef);
      const re_response = await getDoc(sessionsRef);
      const { horaInicio } = userResponse.data();
      const { Movimientos } = response.data() || re_response.data();

      // Construye el nuevo objeto de movimiento
      const nuevoMovimiento = {
        ...horaInicio,
        horaCierre: new Date(),
      };

      // Agrega el nuevo movimiento al principio del array
      Movimientos.unshift(nuevoMovimiento);

      // Actualiza el historial de sesiones y la hora de inicio del usuario en Firestore
      await updateDoc(sessionsRef, { Movimientos });
      await updateDoc(userRef, { horaInicio: null });

      // Cierra la sesión del usuario, limpia el almacenamiento local y redirige al login
      await signOut(auth);
      await localStorage.clear();
      window.location.reload();
      setLoading(false);

      return "Sesión cerrada, hasta luego 🙋";
    } catch (error) {
      console.log("error logOut", error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{ ...state, login, logOut, loading, dataUserAccess, store,dataStore }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Hook personalizado para acceder al contexto de autenticación.
 * @function
 * @returns {AuthState} El estado de autenticación.
 */
// Se define un hook personalizado useAuth que proporciona acceso al contexto de autenticación.
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthState;
