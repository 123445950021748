import React from "react";
import Lottie from "react-lottie";
import ErrorAnimation from "../animated/28828-laboratorio.json";
import { Grid } from "@mui/material";
import { useDimensions } from "../hooks/useDimensions";

/**
 * Componente que muestra un mensaje de error y una animación.
 * @returns {JSX.Element} Elemento JSX que representa el componente Error404.
 */
const Error404 = () => {
  // Dimensiones de la ventana para adaptar el tamaño de la animación.
  const dimensionWidth = useDimensions("width");

  return (
    <>
      {/* Contenedor principal */}
      <Grid container style={design.root}>
        {/* Título */}
        <Grid item xs={12} md={12} xl={12}>
          <h1 style={design.title}>No se encontró lo que buscabas</h1>
        </Grid>
        {/* Animación de error */}
        <Grid item xs={12} md={12} xl={12}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: ErrorAnimation,
            }}
            // Estilo dinámico para adaptar el tamaño de la animación según el ancho de la ventana.
            style={{ width: dimensionWidth < 1000 ? "100%" : "45%" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Error404;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  root: {
    backgroundColor: "#FFF",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 35,
    color: "#2E4666",
  },
};
