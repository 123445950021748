import React, { useState } from "react";
import { Grid } from "@mui/material";
import StepperOrder from "../../Components/Ordenes/V2/StepperOrder";
import { useDimensions } from "../../hooks/useDimensions";
// import TicketOrden from "../../Components/Ordenes/V2/TicketOrden";
import CustomData from "../../Components/Ordenes/V2/Modify/CustomData";
import CustomAddress from "../../Components/Ordenes/V2/Modify/CustomAddress";
import CustomStudies from "../../Components/Ordenes/V2/Modify/CustomStudies";
import CustomPayment from "../../Components/Ordenes/V2/Modify/CustomPayment";
import CustomServiceHours from "../../Components/Ordenes/V2/Modify/CustomServiceHours";
import TicketModify from "../../Components/Ordenes/V2/Modify/TicketModify";
import { Edit } from "@mui/icons-material";

const Modify = () => {
  const dimensionHeight = useDimensions("height");
  const dimensionWidth = useDimensions("width");
  const [typeCreate, setTypeCreate] = useState(false);

  const [activity, setActivity] = useState([]);

  const [touch, setTouch] = useState(`SECTION-0`);

  const scrollToSection = (idx) => {
    const section = document.getElementById(`SECTION-${idx}`);
    setTouch(idx);

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const style = {
    height: `${dimensionHeight}` - 155,
    overflow: "auto",
    paddingLeft: 15,
  };

  const widthTablet = dimensionWidth > 768 && dimensionWidth < 1023;
  const widthTelefono = dimensionWidth > 320 && dimensionWidth < 767;
  const Ticket = widthTablet > widthTelefono;
  const np = 1000;

  const [form, setForm] = useState({
    np: 1000,
    sucursal: "Texcoco",
  });
  return (
    <>
      <Grid container backgroundColor="#D9EDFF" className="C4">
        <Grid item xs={12} md={6} xl={8} lg={8} style={design.ColorFondo}>
          <p style={design.title}>Modificar PED-{form.np}</p>
          <p style={design.textCreate}>
            {form?.sucursal || null}{" "}
          </p>
          <Grid container>
            {dimensionWidth > 1200 && (
              <Grid item xs={0} md={2} xl={2}>
                <StepperOrder
                  activity={activity}
                  onClick={scrollToSection}
                  windowHeight={dimensionHeight}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} style={style}>
              <CustomData />
              <CustomAddress />
              <CustomStudies activity={activity} />
              <CustomPayment activity={activity} />
              
              <CustomServiceHours /> 
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={Ticket ? 5 : 12} sm={12} md={6} lg={4} xl={4}>
          <TicketModify />
        </Grid>
      </Grid>
    </>
  );
};

export default Modify;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    textAlign: "center",
    fontWeight: "500",
    color: "#00518c",
    fontSize: 27,
    marginBottom: 2,
  },
  textEdit: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  textCreate: {
    fontSize: 18,
    textAlign: "center",
    marginRight: "2%",
    marginTop: "-0.5%",
    fontWeight: "600",
    color: "#4496D2",
    fontFamily: "Comic Sans MS, Comic Sans, cursive",
  },
  Edit: {
    fontSize: 17,
    color: "#4496D2",
    marginTop: 5,
  },
  ColorFondo: {
    padding: "0px 5px",
    backgroundColor: "#FFF",
    borderTopRightRadius: 40,
  },
};
