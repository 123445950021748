/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  useEffect,
  useReducer,
  useContext,
  createContext,
  useState,
} from "react";
import QuoteReducer from "./reducer";
import { getBranches } from "../../Apis/getSucursales";
import {
  SELECT_BRANCH,
  SET_AVALAIBLE_BRANCHES,
  SET_BRANCHES,
  SET_COLONIES,
} from "./types";
import { useAuth } from "../Auth_v2/Auth.context";
import { useSnackbar } from "notistack";
import { servidor } from "../../servidor";
import { validateToken } from "../../Apis/validateToken";
import { getZones } from "../../Apis/getZones";
import firebase from "../../firebase";
import {
  validateOrder,
  validateUsuario,
} from "../../Validations/validateOrder";
import { useNavigate } from "react-router-dom";
import timeStamp_toString from "../../Utils/timeStamp_toString";

// Creación del contexto para el estado relacionado con las órdenes
const QuoteContext = createContext();
// Estado inicial para el contexto de órdenes
const initialState = {
  quote: [],
  branches: [],
  branch: null,
  avalibleBranches: [],
  colonies: [],
};
// -------------------------------------------------------------------------
// Componente que proporciona el contexto y gestiona el estado de las órdenes
const QuoteState = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(QuoteReducer, initialState);
  const [form, setForm] = useState({});

  const [shoppingCart, setShoppingCart] = useState([]);
  //Estado para el llenado del paciente
  const [patient, setPatient] = useState({});
  //Estado para esperar
  const [loading, setLoading] = useState(false);

  //Estado de zona
  const [zona] = useState([]);
  /**
   * Lista de ítems de la orden.
   * @type {Object[]}
   */
  const [Orden] = useState([]);
  /**
   * Horarios seleccionados para la orden.
   * @type {Object}
   */
  const [horariosSelected, setHorarios] = useState({});
  /**
   * tag seleccionados para la orden.
   * @type {Object}
   */
  // const [tags, setTags] = useState([]);
  /**
   * Totales calculados para la orden.
   * @type {Object}
   */
  const [totales, setTotales] = useState({ total: 0, subtotal: 0 });
  //estado de sucursal
  const [store, setStore] = useState();

  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();
  const filterFields = (doc) => {
    const {
      nombre,
      cantidad,
      total,
      subtotal,
      descuento,
      id = null,
      clave = null,
      precio,
    } = doc;
    return {
      nombre,
      cantidad,
      total: subtotal,
      subtotal: total,
      precio,
      descuento,
      id: id ?? clave,
    };
  };
  // -------------------------------------------------------------------------

  const handleChangeForm = (e) => {
    const key = e.target?.name;
    const value = e.target?.value;
    setForm((prev) => ({ ...prev, [key]: value }));
    if (key === "direccion.cp") {
      searchBranchByPostalCode(value, true);
    }
  };
  const calculateTotales = () => {
    const total = Orden.reduce((prev, curr) => curr.subtotal + prev, 0);

    setTotales({ subtotal: total, total });
  };

  const handleCustomForm = (key, value) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const searchBranchByPostalCode = async (value = "", isDirection) => {
    try {
      if (value.length === 5) {
        Orden.splice(0, Orden.length);
        shoppingCart.splice(0, shoppingCart.length);
        setTotales({ subtotal: 0, total: 0 });

        zona.splice(0, zona.length);
        const response = await validateToken("", user.uid);

        setStore(response.store);

        const DOMICILIO = await servidor.get(`/Domicilios/Direccion/${value}`);
        const { Domicilios = [] } = DOMICILIO.data;

        if (!Domicilios.length) {
          throw new Error("No se encontró ningún código postal.");
        }

        const response_zone = await getZones(
          state.branches,
          response.store,
          value
        );

        zona.push(Domicilios[0].estado);
        zona.push(response_zone[0].zones[0].Codigo);
        zona.push(response_zone[0].zones[0].Zona);

        const avalibleBranches = state.branches.filter((el) =>
          response_zone.some((f) => {
            return f.branch === el.id;
          })
        );

        if (!avalibleBranches.length && response_zone.length) {
          enqueueSnackbar("Intenta seleccionar la sucursal más cercana", {
            variant: "info",
            preventDuplicate: true,
            autoHideDuration: 1600,
          });
        }
        selectBranch(avalibleBranches[0]);

        dispatch({ type: SET_AVALAIBLE_BRANCHES, payload: avalibleBranches });

        if (!response_zone.length) {
          enqueueSnackbar(
            "Aún no tienes Zonas asignadas, verifica con un administrador",
            {
              variant: "warning",
              preventDuplicate: true,
              autoHideDuration: 1600,
            }
          );
          return;
        }

        const { estado, municipio } = Domicilios[0];
        handleCustomForm("direccion.estado", estado);
        handleCustomForm("direccion.delegacion", municipio);
        handleCustomForm("direccion.cp", value);

        dispatch({
          type: SET_COLONIES,
          payload: Domicilios.map(({ colonia }) => colonia),
        });

        enqueueSnackbar("Verifica las sucursales disponibles", {
          variant: "info",
          preventDuplicate: true,
          autoHideDuration: 1600,
        });
      }
    } catch (error) {
      setStore("");
      dispatch({ type: SELECT_BRANCH, payload: null });
      dispatch({ type: SET_AVALAIBLE_BRANCHES, payload: [] });
      handleCustomForm("direccion.estado", "");
      handleCustomForm("direccion.delegacion", "");
      handleCustomForm("direccion.cp", "");
      if (
        error.message ===
        "Cannot read properties of undefined (reading 'zones')"
      ) {
        enqueueSnackbar(
          "No contamos con disponibilidad, intentalo con otro codigo postal ",
          {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 1600,
          }
        );
      } else {
        enqueueSnackbar("Ocurrió un error al buscar la dirección", {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 1600,
        });
      }
    }
  };

  // Obtener sucursales disponibles al cargar el componente
  // -------------------------------------------------------------------------
  useEffect(() => {
    getBranches(user.uid, localStorage.getItem("token"))
      .then((response) => {
        dispatch({ type: SET_BRANCHES, payload: response });
      })
      .catch(console.log);
  }, [user.uid]);

  // -------------------------------------------------------------------------
  const onProductCountChange = async (count, product) => {
    setShoppingCart((oldShoppingCart) => {
      // Copia el carrito de compras existente a un nuevo arreglo
      const newShoppingCart = [...Object.values(oldShoppingCart)];
      // Encuentra el índice del producto en el nuevo arreglo
      const productIndex = newShoppingCart.findIndex(
        (item) => item.id === product.id
      );
      if (count === 0) {
        // Si el recuento es cero, elimina el producto del arreglo
        if (productIndex !== -1) {
          newShoppingCart.splice(productIndex, 1);
          Orden.splice(productIndex, 1);

          calculateTotales();
        }
      } else {
        // Si el recuento no es cero, actualiza o agrega el producto en el arreglo
        if (productIndex !== -1) {
          // Si el producto ya está en el carrito, actualiza su cantidad
          newShoppingCart[productIndex] = { ...product };
          Orden[productIndex] = product;

          calculateTotales();
        } else {
          // Si el producto no está en el carrito, agrégalo al final del arreglo
          newShoppingCart.push({ ...product, id: product.id });
          Orden.push(product);

          calculateTotales();
        }
      }
      // Devuelve el nuevo arreglo como el nuevo estado del carrito de compras
      return newShoppingCart;
    });
  };

  /**
   * Busca servicios asociados a un usuario específico basándose en su referencia.
   * @async
   * @param {string|null} ref - Referencia del usuario.
   * @returns {Promise<Object|null>} Objeto con información sobre los servicios o null si no hay datos.
   */
  const searchServices = async (ref = null) => {
    if (!ref) return 0;
    console.log(ref);
    const response = await firebase.db
      .collection("PedidosAux")
      .where("DatosCliente.refUsuario", "==", ref)
      .get();

    if (response.empty) return null;

    let firstService = null;
    let lastService = null;
    let numberOfServices = 0;

    response.docs.forEach((doc) => {
      const servicesDates = doc.data()?.services_Dates;

      if (servicesDates) {
        // Verificar y actualizar el primer servicio
        for (const type in servicesDates) {
          if (
            servicesDates[type] &&
            (!firstService || servicesDates[type] < firstService)
          ) {
            firstService = servicesDates[type];
          }
        }

        // Verificar y actualizar el último servicio
        for (const type in servicesDates) {
          if (
            servicesDates[type] &&
            (!lastService || servicesDates[type] > lastService)
          ) {
            lastService = servicesDates[type];
          }
        }

        numberOfServices++;
      }
    });

    return {
      numberOfServices,
      lastService: timeStamp_toString(lastService),
      firstService: timeStamp_toString(firstService),
    };
  };
  /**
   * Busca un usuario por ID en Firestore.
   * @param {string} COllECTION - Colección de Firestore a buscar.
   * @param {string} ID - ID del usuario.
   * @returns {Promise<Object|null>} - Datos del usuario o null si no se encuentra.
   */
  const searchUser = async (COllECTION, ID) => {
    const response = await firebase.db.collection(COllECTION).doc(ID).get();

    if (!response.exists) {
      enqueueSnackbar(
        "No pudimos encontrar a este usuario 🫠 , comunícate con soporte",
        { variant: "warning", preventDuplicate: true, autoHideDuration: 1600 }
      );
      return null;
    }

    return {
      ...response.data(),
      from: COllECTION,
      id: response.id,
      ref: response.ref,
    };
  };
  /**
   * Selecciona un usuario para su uso en la orden.
   * @param {string} value - Valor del usuario a seleccionar.
   */
  const selectUser = async (value) => {
    const [COllECTION, ID] = `${value}`.split("/");

    // console.log(value);
    const user = await searchUser(COllECTION, ID);

    if (!user) return;

    const numberOfServices = await searchServices(user.ref);

    setPatient({ ...user, ...numberOfServices });
    return;
  };
  /**
   * Función para seleccionar una sucursal.
   * @param {object} branch - Sucursal a seleccionar.
   */
  const selectBranch = (branch) => {
    return dispatch({ type: SELECT_BRANCH, payload: branch });
  };

  const [imagePromotion, setImagePromotion] = useState("");
  const [imageDiscounts, setImageDiscounts] = useState("");
  const [imageDiscountsPublic, setImageDiscountsPublic] = useState("");

  const data = async () => {
    if (!store) {
      console.error("El valor de 'store' es inválido:", store);
      return;
    }
    console.log('data Store - quote', store);

    const ref = firebase.db.collection("Franquicias").doc(store);
    const response = await ref.get();

    if (!response.exists) {
      console.error("Document not found");
      return;
    }

    const { imagenPromotion, discounts, discountsPublic } = response.data();
    setImagePromotion(imagenPromotion);
    setImageDiscounts(discounts);
    setImageDiscountsPublic(discountsPublic);

    return;
  };

  useEffect(() => {
    data();
  }, [store]);

  // Actualizar el localStorage con el formulario y el carrito de compras
  // -------------------------------------------------------------------------
  useEffect(() => {
    localStorage.setItem(
      "sharedData",
      JSON.stringify({
        ...form,
        shoppingCart,
      })
    );
  }, [form, shoppingCart]);

  const checkEmailExists = async (correo) => {
    try {
      const emailExistsQuery = await firebase.db
        .collection("Invitados")
        .where("DatosPersonales.correo", "==", correo)
        .where("origen", "==", store)
        .limit(1)
        .get();

      return emailExistsQuery.size;
    } catch (error) {
      console.error("Error al verificar el correo electrónico:", error);
      throw error;
    }
  };
  // -------------------------------------------------------------------------
  const createNewUser = async (user) => {
    try {
      const newDirection = {
        calle: form["direccion.calle"] || null,
        ciudad: form["direccion.ciudad"] || null,
        cp: form["direccion.cp"] || null,
        delegacion: form["direccion.delegacion"] || null,
        estado: form["direccion.estado"] || null,
        numeroExt: form["direccion.numeroExt"] || null,
        numeroInt: form["direccion.numeroInt"] || null,
        refrencia: form["direccion.referencia"] || null,
        nombre: "Hogar",
      };
      const newUser = {
        correo: user.correo,
        nombre: user.nombre,
        telefono: user.telefono,
      };

      // 1. Verificar si el correo electrónico ya existe
      const emailExists = await checkEmailExists(user?.correo);

      if (emailExists.length > 0) {
        throw new Error(
          "El correo electrónico ya está registrado.Te recomendamos buscar al paciente para generar tu orden."
        );
      }

      // 4. Crear en nuevo usuario con el folio actualizado
      const reference = firebase.db.collection("Invitados").doc();
      await reference.set({
        DatosPersonales: newUser,
        fechaAlta: new Date(),
        fechaRU: null,
        Direcciones: [newDirection],
        origen: store,
        terminos: true,
        token: null,
        usuario: null,
        versionAplicacion: process.env.REACT_APP_VERSION,
        versionBuild: 0,
      });

      return reference;
    } catch (error) {
      console.error("Error al crear el nuevo usuario:", error);
      throw error;
    }
  };
  //Funcion para crear la orden
  // -------------------------------------------------------------------------
  const createQuote = async () => {
    try {
      setLoading(true);

      const responsable = firebase.db
        .collection("Franquicias")
        .doc(store)
        .collection("Personal")
        .doc(firebase.auth.currentUser.uid);

      const origen = firebase.db
        .collection("Franquicias")
        .doc(store)
        .collection("Sucursales")
        .doc(state.branch.id);

      const newUser = {
        correo: form["email"] || null,
        nombre: form["name"],
        refUsuario: form["refUsuario"],
        telefono: form["phone"],
      };
      const newDirection = `${form["direccion.calle"] || ""} ${
        form["direccion.ciudad"] || ""
      } ${form["direccion.cp"] || ""} ${form["direccion.delegacion"] || ""} ${
        form["direccion.estado"] || ""
      } ${form["direccion.numeroExt"] || ""} ${
        form["direccion.numeroInt"] || ""
      }`.trim();

      await validateUsuario(newUser);
      await validateOrder(Orden);

      if (!form["direccion.cp"]) {
        throw new Error("Agrega un codigo postal");
      }
      enqueueSnackbar(
        "Procesando tu cotización, por favor, no cierres ni recargues la página. ¡Gracias por tu colaboración!",
        {
          variant: "info",
          preventDuplicate: true,
          autoHideDuration: 1600,
        }
      );

      if (!newUser.refUsuario) {
        const ref = await createNewUser(newUser);
        if (ref) {
          newUser.refUsuario = ref;
          enqueueSnackbar("Se ha creado un nuevo Invitado", {
            variant: "success",
            preventDuplicate: true,
            autoHideDuration: 1600,
          });
        } else {
          newUser.refUsuario = null;
          newUser.correo = null;
        }
      }

      const QUOTE = {
        DatosContacto: newUser,
        Orden: Orden.map(filterFields),
        Pedido: [],
        direccion: newDirection,
        fechaAlta: new Date(),
        franquicia: store || null,
        observaciones: form?.observaciones || null, // revisar estos campos en UI estan invertidos
        responsable: responsable,
        subtotal: totales.subtotal,
        sucursal: state.branch.id || null,
        origen: origen || null,
        total: totales.total,
      };

      const ref = firebase.db.collection("CotizacionesAux").doc();
      await ref.set(QUOTE);
      setLoading(false);

      await ref.collection("HistorialMovimientos").doc("cotizacion").set({
        Movimientos: [],
        Modificaciones: [],
        descripcion: "Creación de la cotización",
        fechaAlta: new Date(),
        origen: origen,
        responsable: responsable,
      });

      setTimeout(async () => {
        try {
          const response = await servidor.post(
            "/api/cotizaciones/newquotation",
            {
              id: ref.id,
              type: store ? "MoviLabs" : "MoviCare",
            }
          );

          // Verificar si la respuesta es exitosa basándonos en el código de estado
          if (response.status === 200) {
            const responseData = response.data;

            // Si el servidor envía un mensaje de error en la respuesta
            if (responseData.msg && responseData.msg.includes("error")) {
              console.error("Error en la respuesta:", responseData.msg);
              enqueueSnackbar(responseData.msg, {
                variant: "error",
                preventDuplicate: true,
                autoHideDuration: 1600,
              });
            } else {
              console.log("Petición exitosa:", responseData.message);
              enqueueSnackbar(responseData.message, {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 1600,
              });
            }
          } else {
            console.error("La solicitud falló con el estado:", response.status);
            enqueueSnackbar(response.status, {
              variant: "warning",
              preventDuplicate: true,
              autoHideDuration: 1600,
            });
          }
        } catch (error) {
          // Captura cualquier error que ocurra durante la solicitud
          console.error(
            "Ocurrió un error durante la solicitud newquotation:",
            error.message
          );
          enqueueSnackbar(error.message, {
            variant: "error",
            preventDuplicate: true,
            autoHideDuration: 1600,
          });
        } finally {
          navigate("/view-quotes");
        }
      }, 4000); // Retraso de 4 segundos (4000 ms)
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      enqueueSnackbar(error.message, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
      console.log("ERROR::", error);
    }
  };

  return (
    <QuoteContext.Provider
      value={{
        ...state,
        handleChangeForm,
        handleCustomForm,
        selectBranch,
        onProductCountChange,
        searchBranchByPostalCode,
        setForm,
        setHorarios,
        setPatient,
        form,
        patient,
        // tags,
        totales,
        shoppingCart,
        store,
        horariosSelected,
        createQuote,
        loading,
        selectUser,
        imagePromotion,
        imageDiscounts,
        imageDiscountsPublic,
      }}
    >
      {children}
    </QuoteContext.Provider>
  );
};

export const useQuote = () => {
  return useContext(QuoteContext);
};

export default QuoteState;
