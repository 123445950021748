import React, { useEffect, useState } from "react";
import { Stack, Alert } from "@mui/material";
import { SoftBlueButton } from "../../CSS/Contained/SoftBlueButton";
import NewUser from "./NewUser";
import ListUsers from "./ListUsers";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import { useScreenSize } from "../../hooks/useScreenSize";

const Users = () => {
  /**
   * Hook que proporciona el tamaño de pantalla actual y devuelve
   * indicadores booleanos para diferentes tamaños de dispositivos.
   *
   * @typedef {Object} ScreenSize
   * @property {boolean} isMobile - Indica si el tamaño de pantalla corresponde a un dispositivo móvil.
   * @property {boolean} isTabletVertical - Indica si el tamaño de pantalla corresponde a una tableta en orientación vertical.
   * @property {boolean} isTabletHorizontal - Indica si el tamaño de pantalla corresponde a una tableta en orientación horizontal.
   * @returns {ScreenSize} Un objeto que contiene los valores booleanos para isMobile, isTabletVertical, e isTabletHorizontal.
   */

  const { isMobile, isTabletVertical, isTabletHorizontal, isDesktop, isXXL } =
    useScreenSize();
  const [newUser, setNewUser] = useState(false);
  const { user, dataUserAccess } = useAuth();
  useEffect(() => {}, [user, dataUserAccess]);

  if (dataUserAccess === "VENTAS_ROL") {
    return (
      <>
        <Alert severity="error" variant="outlined" style={design.alert}>
          Alto ahí! No tienes los accesos necesarios, intenta contactarte con tu
          encargado de sucursal o con el personal de Movicare
        </Alert>
      </>
    );
  }
  return (
    <>
      <h1 style={design.title}>Personal</h1>
      <Stack
        direction={
          isMobile || isTabletVertical
            ? "column"
            : isTabletHorizontal || isDesktop || isXXL
            ? "row"
            : "row" // Puedes definir un valor por defecto aquí si es necesario
        }
        justifyContent="center"
        alignItems="center"
        spacing={2}
        flex={1}
      >
        {newUser ? (
          <NewUser />
        ) : (
          <SoftBlueButton onClick={() => setNewUser(!newUser)}>
            Alta usuario
          </SoftBlueButton>
        )}
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          mt={5}
        >
          <ListUsers />
        </Stack>
      </Stack>
    </>
  );
};

export default Users;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  alert: {
    marginTop: 20,
    width: "95%",
    marginLeft: 20,
  },
};
