/**
 * Valida si un correo electrónico tiene un formato correcto.
 * 
 * @function email_Format
 * @param {string} [email=""] - Dirección de correo electrónico a validar.
 * @throws {Error} Lanza un error si el formato del correo electrónico es inválido.
 * @returns {boolean} Retorna `true` si el correo electrónico es válido.
 */
export const email_Format = (email = "") => {
    const regex =
      //eslint-disable-next-line
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  
    if (!email.match(regex)) {
      throw new Error("Direccion de correo electrónico no válida.");
    }
    return true;
  };
