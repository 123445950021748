/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import CustomTextField from "../../Components/CustomTextField";
import CustomAutocomplete from "../../Components/CustomAutocomplete";
import { DarkGreenButton } from "../../CSS/Contained/DarkGreenButton";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import { useSnackbar } from "notistack";
import { servidor } from "../../servidor";
import { getBranches } from "../../Apis/getSucursales";
import { validateToken } from "../../Apis/validateToken";
import { validateNewUser } from "../../Validations/validateNewUser";
import { stringTo_timeStamp } from "../../Utils/stringToTimeStamp";

const NewUser = () => {
  const { user, token } = useAuth();
   /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();

  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    last_name: "",
    phone: "",
    email: "",
    id_branch: "",
    store_id: "",
    birthday: "",
    rol: "",
    responsable: "",
    imagen:
      "https://www.movicaremx.com/IMG/app_mobile/FormatPNG/Perfil/Ajolomovi_H.png",
  });
  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeCustomId_Branch = (key) => {
    setForm((prev) => ({ ...prev, id_branch: key?.id }));
  };
  const handleChangeCustomRol = (key, value) => {
    setForm((prev) => ({ ...prev, rol: key?.name }));
  };

  const getData = async () => {
    const response = await validateToken("", user.uid);
    const pathResponsable = `Franquicias/${response.store}/Personal/${user.uid}`;

    setForm((prev) => ({
      ...prev,
      store_id: response.store,
      responsable: pathResponsable,
    }));
    // setForm((prev) => ({ ...prev, responsable:  }));
  };

  useEffect(() => {
    getBranches(user.uid, token).then(setSucursales).catch(console.log);
    getData();
  }, [user.uid]);

  const create_new_user = async () => {
    setLoading(true);
    const birthday = stringTo_timeStamp(form.birthday);

    try {
      await validateNewUser(form, birthday);

      await servidor.post(
        "/movilabs/createUsuario",
        { ...form, birthday },
        {
          headers: {
            uid: user.uid,
          },
        }
      );

      enqueueSnackbar(`El usuario ${form.name} se ha ingresado correctamente`, {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });

      await servidor.post("mails/nuevoUsuarioMoviCare", {
        mail: form.email,
        nombre: form.name,
      });
      setLoading(false);

      window.location.reload();
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.data) {
        enqueueSnackbar(error.response.data.error, {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 1600,
        });
      } else {
        enqueueSnackbar(error.message, {
          variant: "error",
          preventDuplicate: true,
          autoHideDuration: 1600,
        });
      }
    }
  };

  return (
    <>
      <Stack direction="column">
        <h1 style={design.title}>Alta usuario</h1>
        <CustomTextField
          label="Correo electrónico"
          type="email"
          name="email"
          value={form.email}
          onChange={handleChangeForm}
        />
        <CustomTextField
          label="Nombre"
          type="text"
          name="name"
          value={form.name}
          onChange={handleChangeForm}
        />
        <CustomTextField
          label="Apellidos"
          type="text"
          name="last_name"
          value={form.last_name}
          onChange={handleChangeForm}
        />
        <CustomTextField
          label="Teléfono"
          type="number"
          name="phone"
          maxLength={10}
          value={form.phone}
          onChange={handleChangeForm}
        />
        <CustomTextField
          label="Fecha de nacimiento"
          type="date"
          name="birthday"
          InputLabelProps={{ shrink: true }}
          value={form.birthday}
          onChange={handleChangeForm}
        />

        <CustomAutocomplete
          label="Sucursal"
          onChange={handleChangeCustomId_Branch}
          options={sucursales}
          getOptionLabel={(option) => option.name}
          required={true}
          renderOption={(props, option) => <p {...props}>{option.name}</p>}
        />
        <CustomAutocomplete
          label="Rol"
          options={rol}
          value={form.rol}
          getOptionLabel={(option) => option.name}
          required={true}
          onChange={handleChangeCustomRol}
        />
        {loading ? (
          <>
            <CircularProgress style={{ marginLeft: 50 }} />
          </>
        ) : (
          <>
            <DarkGreenButton
              disabled={loading}
              onClick={create_new_user}
              width="98%"
            >
              Continuar
            </DarkGreenButton>
          </>
        )}
      </Stack>
    </>
  );
};

export default NewUser;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
};

const rol = [
  { name: "ADMIN_ROL" },
  { name: "VENTAS_ROL" },
  { name: "MASTER_ROL" },
];
