/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  Avatar,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import CustomAutocomplete from "../CustomAutocomplete";
import { DarkGreenButton } from "../../CSS/Contained/DarkGreenButton";
import imagesArticles from "../../Utils/imagesArticles";
import { validateToken } from "../../Apis/validateToken";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import {
  createItem,
  verifyCode,
  verifyFields,
} from "./Controllers/createItem.controller";
import {
  getDepartaments,
  getSubDepartaments,
} from "./Controllers/getDepartments.controller";
import CustomTextField from "../CustomTextField";

const Study = () => {
  const { user, dataUserAccess } = useAuth();
  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [branch, setBranch] = useState();
  const [store, setStore] = useState();
  const [departament, setDepartament] = useState([]);
  const [subdepartament, setSubdepartament] = useState([]);

  const getData = async () => {
    const response = await validateToken("", user.uid);
    setBranch(response.branch);
    setStore(response.store);
    // console.log(response)
    if (response) {
      getDepartaments(store, branch).then(setDepartament);
      getSubDepartaments(store, branch).then(setSubdepartament);
    }
  };

  useEffect(() => {
    getData();
  }, [user, dataUserAccess, store, branch]);

  const [spinner, setSpinner] = useState(false);
  const [form, setForm] = useState({});
  const images = imagesArticles.getImagesArticles();

  const handleSelect = (selectedImage) => {
    if (selectedImage && selectedImage.img) {
      setForm({
        ...form,
        image: selectedImage,
      });
    }
  };

  const handleCreate = async () => {
    const path = `Franquicias/${store}/Sucursales/${branch}/Products`;

    try {
      if (form.code) {
        // Verificar el código preexistente en algun articulo
        await verifyCode(form.code, path);
      }
      // Verificar los campos del formulario
      await verifyFields(form);

      // Si todos los campos son válidos, continuar con la creación del artículo
      setSpinner(true);
      await createItem(form, path, user, store);
      enqueueSnackbar("Artículo creado correctamente", {
        variant: "success",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
      navigate("/view-catalog");
    } catch (error) {
      // Si se produce algún error en la validación o creación del artículo,
      console.error("Error al crear el artículo:", error);
      enqueueSnackbar(error.message, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 1600,
      });
    } finally {
      // Finalizar el spinner independientemente de si la creación del artículo fue exitosa o no
      setSpinner(false);
    }
  };

  if (dataUserAccess === "VENTAS_ROL") {
    return (
      <>
        <Alert severity="error" variant="outlined" style={design.alert}>
          Alto ahí! No tienes los accesos necesarios, intenta contactarte con tu
          encargado de sucursal o con el personal de Movicare
        </Alert>
      </>
    );
  }

  return (
    <Grid container>
      <CustomAutocomplete
        onChange={(value) => setForm({ ...form, departament: value })}
        label="Departamento"
        required={true}
        onClick={getData}
        getOptionLabel={(option) => option}
        options={departament}
        grid={4}
        gridXs={12}
      />
      <Grid xs={12} md={3.8} xl={3.8} lg={3.8}>
        <CustomAutocomplete
          onChange={(value) => setForm({ ...form, subdepartament: value })}
          label="Subdepartamento"
          required={true}
          onClick={getData}
          getOptionLabel={(option) => option}
          options={subdepartament}
          grid={12}
        />
        <Grid xs={12} md={12} xl={12} lg={12} style={design.align}>
          <Box sx={design.Box}>
            <p style={design.text}>
              En caso de no conocer el subdepartamento, seleccionar varios.
            </p>
          </Box>
        </Grid>
      </Grid>
      <CustomTextField
        id="clave"
        label="Clave"
        grid={4}
        gridXs={11.5}
        required={true}
        variant="outlined"
        value={form?.code}
        onChange={(e) => setForm({ ...form, code: e.target.value })}
        onKeyUp={(e) =>
          setForm({ ...form, code: e.target.value.toUpperCase() })
        }
        helperText={
          form?.code?.length === 0
            ? null
            : form?.code?.length < 4 && "Mínimo 3 carácteres"
        }
      />
      <CustomTextField
        id="nombre"
        label="Nombre del estudio"
        required={true}
        variant="outlined"
        value={form.name}
        grid={4}
        gridXs={11.5}
        onChange={(name) => setForm({ ...form, name: name.target.value })}
        onKeyUp={(e) =>
          setForm({ ...form, name: e.target.value.toUpperCase() })
        }
        helperText={
          form?.name?.length === 0
            ? null
            : form?.name?.length < 4 && "Mínimo 3 carácteres"
        }
      />
      <Grid container xs={11.5} md={3.8} xl={3.8} lg={3.8}>
        <Grid xs={1.5} md={1.5} xl={1.5} style={design.alinear}>
          <Avatar src={form?.image?.img} />
        </Grid>
        <CustomAutocomplete
          value={form.name}
          onChange={handleSelect}
          label="Imagen"
          required={true}
          getOptionLabel={(option) => option.name}
          options={images}
          grid={10.5}
          gridXs={10.5}
        />
      </Grid>
      <CustomTextField
        id="costo"
        label="Costo $"
        required={true}
        variant="outlined"
        type="number"
        min="1"
        grid={4}
        gridXs={11.5}
        value={form.cost}
        onChange={(cost) => {
          if (cost.target.value >= 0) {
            setForm({ ...form, cost: cost.target.valueAsNumber });
          }
        }}
        InputProps={{
          inputProps: { min: 0 },
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">.00</InputAdornment>,
        }}
        helperText="El costo siempre debe ser menor al precio del público."
      />
      <CustomTextField
        id="precio"
        label="Precio al publico $"
        required={true}
        variant="outlined"
        type="number"
        min="1"
        grid={4}
        gridXs={11.5}
        value={form.price}
        onChange={(price) => {
          if (price.target.value >= 0) {
            setForm({ ...form, price: price.target.valueAsNumber });
          }
        }}
        InputProps={{
          inputProps: { min: 0 },
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          endAdornment: <InputAdornment position="end">.00</InputAdornment>,
        }}
        helperText={
          form?.price?.length === 0
            ? null
            : +form.cost > +form.price && "El precio debe ser mayor al costo"
        }
      />
      <CustomTextField
        id="tiempo"
        label="Tiempo de entrega"
        required={true}
        variant="outlined"
        grid={3.8}
        gridXs={11.5}
        value={form.deliveryTime}
        onChange={(name) =>
          setForm({ ...form, deliveryTime: name.target.value })
        }
        onKeyUp={(e) => setForm({ ...form, deliveryTime: e.target.value })}
        helperText="Ingresar tiempo en días"
      />
      <CustomTextField
        id="descripcion"
        label="Descripción"
        required={true}
        variant="outlined"
        grid={4}
        gridXs={11.5}
        value={form.description}
        onChange={(name) =>
          setForm({ ...form, description: name.target.value })
        }
        onKeyUp={(e) => setForm({ ...form, description: e.target.value })}
        helperText={
          form?.description?.length === 0
            ? null
            : form?.description?.length < 4 && "Mínimo 3 carácteres"
        }
      />
      <CustomTextField
        id="requisitos"
        label="Requisitos"
        multiline={5}
        maxRows={4}
        grid={6}
        gridXs={11.5}
        required={true}
        variant="outlined"
        helperText={
          form?.req?.length === 0
            ? null
            : form?.req?.length < 11 &&
              "Mínimo 10 carácteres.Si requieres dar saltos de línea coloca +"
        }
        value={form.req}
        onChange={(req) => setForm({ ...form, req: req.target.value })}
      />
      <CustomTextField
        id="recom"
        label="Recomendaciones"
        multiline={5}
        maxRows={4}
        required={false}
        variant="outlined"
        grid={5.8}
        gridXs={11.5}
        helperText={
          form?.recomd?.length === 0
            ? null
            : form?.recomd?.length < 11 &&
              "Mínimo 10 carácteres. Si requieres dar saltos de línea coloca //"
        }
        value={form.recomd}
        onChange={(recomd) => setForm({ ...form, recomd: recomd.target.value })}
      />
      <Grid xs={12} md={12} xl={12} textAlign="center">
        {spinner ? (
          <CircularProgress style={{ marginTop: 30 }} />
        ) : (
          <DarkGreenButton width={"70%"} height={"70px"} onClick={handleCreate}>
            Crear estudio
          </DarkGreenButton>
        )}
      </Grid>
    </Grid>
  );
};
export default Study;
/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  Box: {
    backgroundColor: "#BDE0FE",
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    width: "96%",
    marginTop: -1.5,
    display: "flex",
  },
  align: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    color: "#093046",
    fontFamily: "Lexend",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 550,
    paddingLeft: 15,
    alignSelf: "flex-end",
  },
  Alert: {
    backgroundColor: "#F08050",
    width: "95%",
    height: 50,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 1,
    marginLeft: 1,
  },
  info: { color: "#FFF", marginLeft: 10 },
  txtAlert: {
    color: "#FFF",
    alignSelf: "center",
    fontWeight: 400,
    marginLeft: "3%",
  },
  alinear: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  alert: {
    width: "91%",
    fontSize: 12,
    marginTop: "2%",
    marginBottom: "2%",
    marginLeft: "5%",
    borderRadius: 15,
  },
};
