import { useEffect, useState, useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebasev2";

/**
 * Custom hook to fetch data by a provided reference.
 *
 * This hook fetches data from Firestore based on the provided reference object.
 *
 * @module useReferenceData
 * @param {Object|null} [reference=null] - The reference object to fetch data.
 * @returns {Object} An object containing the fetched data.
 * @property {Object[]} referenceData - The fetched data.
 */
export const useReferenceData = (reference) => {
  const [referenceData, setReferenceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Fetch data from Firestore references.
   *
   * This function retrieves data from multiple Firestore references
   * and sets the state with the fetched data.
   *
   * @param {Object} reference - The reference object containing Firestore refs.
   */
  const fetchData = useCallback(async (reference) => {
    setLoading(true);
    setError(null); // Reset errors before starting the fetch

    try {
      const entries = Object.entries(reference);

      // Map over the entries to fetch data from Firestore
      const promises = entries.map(async ([key, value]) => {
        if (!value?._path?.segments) return null;

        let docSnap;
        // If the reference points to a subcollection, we use this path
        if (value._path.segments.length > 2) {
          const ref = doc(
            db,
            value._path.segments[0], // Colección principal
            value._path.segments[1], // ID del documento en la colección principal
            value._path.segments[2], // Subcolección
            value._path.segments[3] // ID del documento en la subcolección
          );

          docSnap = await getDoc(ref);
        } else {
          // Otherwise, it's a document in a main collection
          const ref = doc(db, value._path.segments[0], value._path.segments[1]);

          docSnap = await getDoc(ref);
        }

        // If the document exists, return its data, otherwise return null
        return docSnap.exists ? { id: docSnap.id, ...docSnap.data() } : null;
      });

      // Wait for all promises to resolve
      const results = await Promise.all(promises);

      // Filter out any null results and update state
      setReferenceData(results.filter(Boolean));
    } catch (error) {
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  }, []);

  // useEffect to trigger data fetching when the reference changes
  useEffect(() => {
    if (!reference || Object.keys(reference).length === 0) {
      return;
    }

    fetchData(reference);
  }, [reference]);

  return { referenceData };
};
