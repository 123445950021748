import React, { useState } from "react";
import { Box, Tab, Tabs, Typography, Alert } from "@mui/material";
import Details from "./Details";
import History from "./History";

const ModalDetails = ({
  study,
  setOpenModal,
  dataUserAccess,
  infoFranquicia,
}) => {
  const [tabs, setTabs] = useState(0);

  const hendTabs = (event, newValue) => setTabs(newValue);

  const tabsFuncion = (index) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 1 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };
  return (
    <>
      <Tabs
        variant="scrollable"
        aria-label="Vertical tabs example"
        value={tabs}
        onChange={hendTabs}
      >
        {TABS.map((doc, idx) => {
          return (
            <Tab
              style={{
                color: idx === tabs ? "#00518C" : "#9e9e9e",
                ...design.Tab,
              }}
              icon={
                <Box
                  component="img"
                  src={doc.img}
                  alt="Órdenes"
                  width={40}
                  height={40}
                  sx={{ opacity: idx === tabs ? 1 : 0.5 }}
                />
              }
              label={doc.label}
              {...tabsFuncion(idx)}
            />
          );
        })}
      </Tabs>
      <TabPanel value={tabs} index={0}>
        {dataUserAccess === "VENTAS_ROL" ? (
          <>
            <>
              <Alert severity="error" variant="outlined" style={design.alert}>
                Alto ahí! No tienes los accesos necesarios, intenta contactarte
                con tu encargado de sucursal o con el personal de Movicare
              </Alert>
            </>{" "}
          </>
        ) : (
          <Details articulo={study} setOpenModal={setOpenModal} />
        )}
      </TabPanel>
      <TabPanel value={tabs} index={1}>
        <History
          articulo={study}
          setOpenModal={setOpenModal}
          infoFranquicia={infoFranquicia}
        />
      </TabPanel>
    </>
  );
};
export default ModalDetails;
const TABS = [
  {
    label: "Detalles",
    img: "https://movicaremx.com/IMG/app_mobile/FormatPNG/0pedidos.png",
  },
  {
    label: "Historial de movimientos",
    img: "https://www.movicaremx.com/IMG/app_mobile/FormatPNG/Navbar/Orden.png",
  },
];
/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  Tab: {
    width: "50%",
    fontFamily: "Lexend",
    fontSize: "12px",
    fontStyle: "normal",
    textTransform: "initial",
  },
};
