/* eslint-disable import/no-anonymous-default-export */
/// Importacion de tipos de acciones
/**
 * Reducer para gestionar el estado relacionado con las sucursales y colonias.
 * @param {Object} state - Estado actual.
 * @param {Object} action - Acción a realizar.
 * @param {string} action.type - Tipo de acción.
 * @param {*} action.payload - Datos asociados con la acción.
 * @returns {Object} Nuevo estado después de aplicar la acción.
 */
import {
    SELECT_BRANCH,
    SET_AVALAIBLE_BRANCHES,
    SET_BRANCHES,
    SET_COLONIES,
  } from "./types";
  
  // Definicion del *reducer*
  export default (state, action) => {
    switch (action.type) {
      /**
       * Caso para establecer las sucursales.
       * @type {SET_BRANCHES}
       * @param {Array} action.payload - Sucursales a establecer.
       */
      case SET_BRANCHES:
        return { ...state, branches: action.payload };
      /**
       * Caso para establecer las sucursales disponibles.
       * @type {SET_AVAILABLE_BRANCHES}
       * @param {Array} action.payload - Sucursales disponibles a establecer.
       */
      case SET_AVALAIBLE_BRANCHES:
        return { ...state, avalibleBranches: action.payload };
      /**
       * Caso para seleccionar una sucursal.
       * @type {SELECT_BRANCH}
       * @param {*} action.payload - Sucursal seleccionada.
       */
      case SELECT_BRANCH:
        return { ...state, branch: action.payload };
      /**
       * Caso para establecer las colonias.
       * @type {SET_COLONIES}
       * @param {Array} action.payload - Colonias a establecer.
       */
      case SET_COLONIES:
        return { ...state, colonies: action.payload };
      default:
        return state;
    }
  };
  